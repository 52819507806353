import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import "../Styles/components/filter.css";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid } from "@mui/material";
import dayjs from "dayjs"; // Import dayjs library

function Filter({ getData }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize selectedDate with Day.js object

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (applyFilter = false) => {
    if (applyFilter) {
      const year = selectedDate.year();
      const month = selectedDate.month() + 1;
      getData(month, year);
    } else {
      // Reset: Fetch all data
      getData();
    }
    setAnchorEl(null); // Close the popover
  };

  const handleDateChange = (date) => {
    setSelectedDate(date); // Update selectedDate state when date changes
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="filter_filed">
      <Button
        disableRipple
        disableElevation
        aria-describedby={id}
        onClick={handleClick}
      >
        <FilterAltIcon />
        Filter
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          <div className="filter_list">
            <div className="filter_list_item">
              <h3>Filter</h3>
              <div className="filter_dates">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Months"
                        views={["year", "month"]}
                        openTo="year"
                        value={selectedDate} 
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </div>
              <div className="rest-apply">
                <Button
                  className="reset"
                  disableRipple
                  disableElevation
                  onClick={() => handleClose()}
                  >
                  Reset
                </Button>
                <Button
                  className="apply"
                  disableRipple
                  disableElevation
                  onClick={() => handleClose(true)}
                  >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </Typography>
      </Popover>
    </div>
  );
}

export default Filter;
