import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/user_management.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import AddRoundedIcon from "@mui/icons-material/AddRounded";
import add_sales_image from '../assets/images/icons/add-icons/add.svg'
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
// import * as XLSX from 'xlsx';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
// import ExcelIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Link } from "react-router-dom";
import microsoftexcel from '../assets/images/icons/excel.png'
import instance from "../utlis/Api";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Sales_list() {

  //menu_import
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setentryDate();
    setProductCategory();
    setproductCode();
    setproduct();
    setProducts("");
    setsalesVolume();
    setsize();

    //it defines edit values will be null
    setEditingSales(null)
  };


  const [sales, setsales] = useState([]);
  const [editingSales, setEditingSales] = useState(null);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10); // Number of users to display per page
  // Calculate pagination values based on active sales entries
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const activeSales = sales.filter(sale => sale.status === "Active");
  const currentsales = activeSales.slice(indexOfFirstUser, indexOfLastUser);
  // Function to handle pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  //permission
  const [permissions, setPermissions] = useState({ read: false, write: false });

  //get the token in localstorage
  const token = localStorage.getItem("token");
  //get id in local storage
  const id = localStorage.getItem('userId');

  //get sales data
  useEffect(() => {
    getsales();
  }, []);

  const getsales = async () => {
    try {
      const response = await instance.get("salesEntries");
      setsales(response.data.salesEntries);
      console.log(response.data.salesEntries);
    } catch (error) {
      console.error("Error fetching sales:", error.message);
    }
  };

  //getpermissions
  async function getPermissions() {
    try {
      const response = await instance.get(`users/permissions/${id}`);
      if (response.data.status === 'success') {
        const perm = response.data.data.find(item => item.itemName === "SalesEntry")
        // console.log(perm)
        setPermissions({ read: perm.read, write: perm.write })
      }
    } catch (err) {
      console.log(err);
    }
  }
  //get sales data
  useEffect(() => {
    getPermissions();
  }, []);


  const addsales = async () => {
    const formattedDate = dayjs(entryDate).format("YYYY-MM-DD");

    if (!entryDate ||
      !productCategory ||
      !productCode ||
      !product ||
      !salesVolume ||
      !size) {
      toast.error("All fields are required. Please fill in all the fields.");
      return;
    }

    if (isNaN(size)) {
      toast.error("Size must be a number. Please enter a valid number for size.");
      return;
    }

    try {
      const response = await instance.post(
        "salesEntries",
        {
          entryDate: formattedDate,
          productCategory,
          productCode,
          product,
          salesVolume,
          size,
        });
      console.log(response.data.salesEntries);
      setentryDate();
      setProductCategory();
      setproductCode();
      setproduct();
      setsalesVolume();
      setsize();
      //refresh the added sales
      getsales();
      //close the popup
      handleClose()

    } catch (error) {
      console.error("Error adding sales:", error.message);
      toast.error("Error adding sales")
    }
  };


  //add sales
  const [entryDate, setentryDate] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productCode, setproductCode] = useState("");
  const [product, setproduct] = useState("");
  const [salesVolume, setsalesVolume] = useState("");
  const [size, setsize] = useState("");

  const handleEditClick = (sale) => {
    setEditingSales(sale);
    const formattedDate = dayjs(sale.entryDate);
    console.log(formattedDate);

    setentryDate(formattedDate || "");
    setProductCategory(sale.productCategory?._id || "");
    setproduct(sale.productName || "");
    setproductCode(sale.productCode || "");
    setsalesVolume(sale.salesVolume || "");
    setsize(sale.size || "");

    setOpen(true);
  };



  const updateSales = async () => {
    const formattedDate = dayjs(entryDate).format("YYYY-MM-DD");
    try {
      const response = await instance.patch(
        `salesEntries/${editingSales._id}`,
        {
          entryDate: formattedDate,
          productCategory,
          product,
          productCode,
          size,
          salesVolume,
        }
      );

      console.log(response.data);
      setEditingSales("");
      setProductCategory("");
      setproduct("");
      setentryDate("");
      setproductCode("");
      setsize("");
      setsalesVolume("");
      getsales();
      handleClose();

      getsales();
    } catch (error) {
      console.error("Error updating sales:", error.message);
      toast.error("Error updating sales")
    }
  };

  //get the productcategories
  const [productCategories, setProductCategories] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProductCategories();
  }, []);

  const getProductCategories = async () => {
    try {
      const response = await instance.get(
        "productCategories");
      setProductCategories(response.data.productCategories);
      console.log(response.data.productCategories);
    } catch (error) {
      console.error("Error fetching product categories:", error.message);
    }
  };


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await instance.get(
          `products/category/${productCategory}`);
        setProducts(response.data.products);
      } catch (error) {
        console.error("Error fetching products:", error.message);
      }
    };

    if (productCategory) {
      fetchProducts();
    }
  }, [productCategory]);
  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const handleProductSelection = (event, newValue) => {
    setproduct(newValue);


    const selectedProduct = products.find(
      (item) => item.productName === newValue
    );


    if (selectedProduct) {
      setproductCode(selectedProduct.productCode);
      setsize(selectedProduct.size); // Set the size when a product is selected
    }
  };

  const handleProductCategoryChange = (e) => {
    setProductCategory(e.target.value);

    setproduct("");
    setproductCode("");

    setAutocompleteKey((prevKey) => prevKey + 1);
  };


  // Call your delete API or function here
  const [openDialog, setOpenDialog] = useState(false);
  const [saleIdToDelete, setSaleIdToDelete] = useState(null);


  const handleDeleteClick = (saleId) => {
    setSaleIdToDelete(saleId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSaleIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {

      await instance.delete(
        `salesEntries/${saleIdToDelete}`);

      getsales();
      console.log(`Sale with ID ${saleIdToDelete} deleted successfully.`);
    } catch (error) {
      console.error(
        `Error deleting sale with ID ${saleIdToDelete}:`,
        error.message
      );
      toast.error("Error deleting sales")
    } finally {
      handleCloseDialog();
    }
  };




  //export data integration
  const handleDownloadExportData = async () => {
    try {
      const response = await instance.get("salesEntries/export", {
        responseType: 'blob', });

      downloadExportData(response.data);
      console.log(response.data)
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching export data:", error.message);
    }
  };


  const downloadExportData = (data) => {
    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "salesEntries.xlsx"); // Set the filename for the downloaded file
      document.body.appendChild(link);
      link.click();
    }
  };


  // const handleDownloadExportData = async () => {
  //   try {
  //     // Fetch all sales entries
  //     const response = await axios.get("https://backendepr.vibhohcm.com/api/v1/salesEntries", {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       }
  //     });

  //     // Filter active sales entries
  //     const activeSalesEntries = response.data.salesEntries.filter(entry => entry.status === "Active");

  //     // Convert and download data as Excel
  //     await downloadExcel(activeSalesEntries);
  //   } catch (error) {
  //     console.error("Error fetching or exporting sales entries:", error.message);
  //   }
  // };

  // const downloadExcel = (data) => {
  //   if (data.length === 0) {
  //     console.error("No data to export.");
  //     return;
  //   }

  //   // Convert data to Excel format
  //   const worksheet = XLSX.utils.json_to_sheet(data);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "SalesEntries");

  //   // Save the workbook to a file and trigger download
  //   XLSX.writeFile(workbook, "salesEntries.xlsx");
  // };





  // //import data 
  // const [file, setFile] = useState(null);

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   // Check if the selected file is an XLS file
  //   if (selectedFile && selectedFile.name.endsWith('.xlsx')) {
  //     setFile(selectedFile);
  //   } else {
  //     console.error('Please select a valid xlsx file.');
  //   }
  // };

  // const handleImport = async () => {
  //   if (!file) {
  //     console.error('Please select a file.');
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('file', file);

  //   try {
  //     const response = await axios.post("https://backendepr.vibhohcm.com/api/v1/salesEntries/import", formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         Authorization: `Bearer ${token}`, // Add authorization token if required
  //       },
  //     });
  //     console.log('Import successful:', response.data);
  //     // Optionally, handle success response or perform any necessary actions
  //   } catch (error) {
  //     console.error('Error importing data:', error.message);
  //     // Optionally, handle error response or display error message to the user
  //   }
  // };


  // //sample file
  // const openSampleFile = () => {
  //   // Create an empty XLSX file with a Blob
  //   const columnNames = ['Entry Date', 'Product Name', 'Product Category', 'Sales Volume', 'Product Code', 'Size'];
  //   const allData = [columnNames];
  //   const worksheet = XLSX.utils.aoa_to_sheet(allData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  //   const content = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
  //   const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //   const url = window.URL.createObjectURL(blob);

  //   // Create an anchor element
  //   const link = document.createElement('a');
  //   link.href = url;

  //   // Set the download attribute to specify the filename
  //   link.setAttribute('download', 'salesEntries sampleFile.xlsx'); // Set the filename to 'sampleFiles.xlsx'

  //   // Trigger download
  //   link.click();

  //   // Clean up
  //   window.URL.revokeObjectURL(url);
  // };



  return (
    <div className="sales_entry">
      <React.Fragment>
        <BootstrapDialog
          className="sales_entry"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {editingSales ? "update sales" : "Add sales"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Entry Date *"
                          value={entryDate}
                          onChange={(selectedDate) =>
                            setentryDate(selectedDate)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Product Category *
                        </InputLabel>
                        <Select
                          labelId="product-category-label"
                          id="product-category"
                          label='Select Product Category *'
                          value={productCategory}
                          onChange={handleProductCategoryChange}
                        >
                          {productCategories.map((category) => (
                            <MenuItem key={category?._id} value={category?._id}>
                              {category?.productCategory}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        {products.length > 0 ? (
                          <Autocomplete
                            key={autocompleteKey}
                            freeSolo
                            id="product-name"
                            options={products.map(
                              (product) => product.productName
                            )}
                            value={product}
                            onChange={handleProductSelection}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Product Name *"
                              />
                            )}
                          />
                        ) : (
                          <TextField
                            disabled
                            label="Select Product Name *"
                            value="There are no products for this category"
                          />
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Product Code *"
                      variant="outlined"
                      value={productCode}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Sales Volume *"
                      variant="outlined"
                      value={salesVolume}
                      onChange={(e) => setsalesVolume(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Size *"
                      variant="outlined"
                      // value={size}
                      // onChange={(e) => setsize(e.target.value)}
                      value={size}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="cancel_save_user">
              <Button
                disableRipple
                disableElevation
                className="cancel"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                disableRipple
                disableElevation
                className="save_user"
                onClick={editingSales ? updateSales : addsales}
              >
                {editingSales ? "Update Sales" : "Save Sales"}
              </Button>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <React.Fragment>
        <BootstrapDialog
          className="sales_delete"
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
        >
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <div className="delete_sales_list">
                  <h3>Delete Sales List</h3>
                  <p>
                    Are you sure you want to delete the Sales record from Sales
                    list?
                  </p>
                  <div className="cancel_save_user">
                    <Button
                      disableRipple
                      disableElevation
                      className="cancel"
                      onClick={handleCloseDialog}
                    >
                      Cancel
                    </Button>
                    <Button
                      disableRipple
                      disableElevation
                      className="save_user"
                      onClick={handleConfirmDelete}
                    >
                      Yes, Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <Box>
        <Box >
          <Topbar />
          <section className="main_box">
            <div className="table_box">
              <TableContainer component={Paper}>
                <div className="table_inbox sales_inbox">
                  <div className="add_user_list">
                    <div className="add_user_content">
                      <h3>Sales Entry</h3>
                    </div>
                    <div className="add_user">
                      <div className={!permissions.write ? "disable_deactive" : "import_menu"}>
                        <IconButton
                          id="fade-button"
                          aria-controls={open1 ? 'fade-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open1 ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          {/* <ExcelIcon /> */}
                          <img src={microsoftexcel} alt="microsoft-excel" />
                        </IconButton>
                        <Menu
                          id="fade-menu"
                          MenuListProps={{
                            'aria-labelledby': 'fade-button',
                          }}
                          anchorEl={anchorEl}
                          open={open1}
                          onClose={handleClose1}
                          TransitionComponent={Fade}
                        >
                          <MenuItem onClick={handleDownloadExportData}>Export</MenuItem>
                          <Link to="/importsalesentryfile"><MenuItem onClick={handleClose1}>Import</MenuItem></Link>
                        </Menu>
                      </div>
                      <Button
                        variant="outlined"
                        disableRipple
                        disableElevation
                        onClick={handleClickOpen}
                        // disabled={!permissions.write}
                        className={!permissions.write ? "disable_deactive" : "add_user button"}
                      >
                        <span className="icon">
                          {/* <AddRoundedIcon /> */}
                          <img className="add_user_image" src={add_sales_image} />
                        </span>
                        <span className="content">Add Sales</span>
                      </Button>
                      {/* <div className={!permissions.write ? "disable_deactive" : "add_user button"}>
                        <Button variant="contained" onClick={handleDownloadExportData}>
                          Export
                        </Button>
                      </div> 
                      <div className={!permissions.write ? "disable_deactive" : "add_user button"}>
                        <input type="file" accept=".xlsx" onChange={handleFileChange} />
                        <Button onClick={handleImport}>Import</Button>
                      </div>
                      <div className={!permissions.write ? "disable_deactive" : "add_user button"}>
                        <Button onClick={openSampleFile}>Open Sample File</Button>
                      </div>*/}

                    </div>
                  </div>
                  <Table
                    className="adduser_table user_table product_table"
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow className="table_head">
                        <TableCell className="first-child">Sl.No</TableCell>
                        <TableCell align="left">Entry Date</TableCell>
                        <TableCell align="left">Product Category</TableCell>
                        <TableCell align="left">Product Code</TableCell>
                        <TableCell align="left">Product</TableCell>
                        <TableCell align="left">Sales Volume</TableCell>
                        <TableCell align="left">Size</TableCell>
                        <TableCell align="center" className={!permissions.write ? "disable_deactive" : ""}>Manage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table_body">
                      {currentsales.filter(sale => sale.status === "Active").map((sale, index) => (
                        <TableRow key={sale._id}>
                          <TableCell align="left">{(index + 1) + (currentPage - 1) * usersPerPage}</TableCell>
                          <TableCell align="left">{dayjs(sale.entryDate).format("YYYY-MM-DD")}</TableCell>
                          <TableCell align="left">{sale?.productCategory?.productCategory}</TableCell>
                          <TableCell align="left">{sale.productCode}</TableCell>
                          <TableCell align="left">{sale.productName}</TableCell>
                          <TableCell align="left">{sale.salesVolume}</TableCell>
                          <TableCell align="left">{sale.size}</TableCell>
                          <TableCell align="center" className={!permissions.write ? "disable_deactive" : ""}>
                            <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleEditClick(sale)}
                              // className="edit_button"
                              // disabled={!permissions.write}
                              className={!permissions.write ? "disable_deactive" : "edit_button"}
                            >
                              <BorderColorRoundedIcon />
                            </Button>
                            <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleDeleteClick(sale._id)}
                              // className="edit_button delete_button"
                              // disabled={!permissions.write}
                              className={!permissions.write ? "disable_deactive" : "edit_button delete_button"}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="pagination_table">
                    <Pagination
                      // count={Math.ceil(sales.length / usersPerPage)}
                      // page={currentPage}
                      // onChange={(event, page) => paginate(page)}
                      count={Math.ceil(activeSales.length / usersPerPage)}
                      page={currentPage}
                      onChange={(event, page) => paginate(page)}
                    />
                  </div>
                </div>
              </TableContainer>
            </div>
          </section>
        </Box>
      </Box >
    </div >
  );
}

export default Sales_list;
