import {Box, Typography, Button,Modal} from '@mui/material';
import "../Styles/layouts/topnav.css";
import * as XLSX from 'xlsx';
import React, { useState } from "react";
import axios from "axios";
import ExcelIcon from '@mui/icons-material/InsertDriveFileOutlined';
import error_icon from '../assets/images/close.png';
import success_icon from '../assets/images/check-mark.png';
import instance from '../utlis/Api';





const Importsalesentryfile = () => {
    const [importResult, setImportResult] = useState({ success: false, message: '' });
    //import data 
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // Check if the selected file is an XLS file
    if (selectedFile && selectedFile.name.endsWith('.xlsx')) {
      setFile(selectedFile);
    } else {
      console.error('Please select a valid xlsx file.');
     
    }
  };

  const handleImport = async () => {
    if (!file) {
      console.error('Please select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await instance.post("salesEntries/import", formData);
      console.log('Import successful:', response.data);
      if (response.data.status === "success") {
        setImportResult({ success: true, message: 'Sales entry imported successfully.' });
    }
   
    } catch (error) {
      console.error('Error importing data:', error);
      // Optionally, handle error response or display error message to the user
    setImportResult({ success: false, message: error.response.data.errors[0].message  });
    }
  };


  //sample file
  const openSampleFile = () => {
    // Create an empty XLSX file with a Blob
    const columnNames = ['Entry Date', 'Product Name', 'Product Category', 'Sales Volume', 'Product Code', 'Size'];
    const allData = [columnNames];
    const worksheet = XLSX.utils.aoa_to_sheet(allData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const content = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
    const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element
    const link = document.createElement('a');
    link.href = url;

    // Set the download attribute to specify the filename
    link.setAttribute('download', 'salesEntries sampleFile.xlsx'); // Set the filename to 'sampleFiles.xlsx'

    // Trigger download
    link.click();

    // Clean up
    window.URL.revokeObjectURL(url);
  };


  const handleCloseModal = () => {
    setImportResult({ success: false, message: '' });
};

  return (
    <div className='import_products'>
    <Box>
         <Typography variant="h5"> Import Salesentry</Typography>
         <Button onClick={openSampleFile}><ExcelIcon />Download sample</Button> 
    </Box>
    <div className='import_products_rules'>
    <Typography variant="h5">NOTE: <span>Please fill the excel with the below columns data</span></Typography>
       <ul>
          <li>All fields are mandatory.</li>
          <li>Please check for the provided category name the product name, product code, and size exists and the provided values are correct.</li>
       </ul>
    </div>
    <div className='upload_product'>
    <Button onClick={openSampleFile}><ExcelIcon />Download sample</Button> 
        <div className='uploaad_product_file'>
             <input type="file" accept=".xlsx" onChange={handleFileChange}/>
             <Button onClick={handleImport}>Upload</Button>
        </div>
    </div>
          <Modal  className='modal_main'
                open={importResult.message !== ''}
                onClose={handleCloseModal}
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'white',  boxShadow: 24, p: 4, width: 400 }}>
                    <Typography  variant="h5" className='errorMessage'>{importResult.success ? <img src={success_icon} alt=''/> : <img src={error_icon} alt=''/>}</Typography>
                    <Typography  variant="body1" className='errorMessage_1'>{importResult.message}</Typography>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Box>
          </Modal>
</div>

  )
}

export default Importsalesentryfile