import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/user_management.css";
import "../Styles/pages/product_components.css";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import add_sales_image from '../assets/images/icons/add-icons/add.svg'
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import category from "../assets/images/icons/category.svg";
import product_bg from "../assets/images/icons/product_bg.svg";
// import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
// import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import edit_btn from '../assets/images/icons/add-icons/edit.svg'
import delete_btn from '../assets/images/icons/add-icons/delete.svg';
import microsoftexcel from '../assets/images/icons/excel.png'
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import instance from "../utlis/Api";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Product_components() {
  //menu_import
  const [anchorE2, setAnchorE2] = useState(null);
  const open2 = Boolean(anchorE2);
  //get the productcategories
  const [productCategories, setProductCategories] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectedProductComponent, setSelectedProductComponent] = useState(null);
  const token = localStorage.getItem("token");//get the token in localstorage
  const id = localStorage.getItem('userId');    //get id in local storage
  const [productcomponents, setproductcomponents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(9); // Change the number to the desired value // Number of users to display per page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentproductcomponents = productcomponents.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(productcomponents.length / usersPerPage);
  const [permissions, setPermissions] = useState({ read: false, write: false });
  // Call your delete API or function here
  const [openDialog, setOpenDialog] = useState(false);
  const [componentIdToDelete, setcomponentIdToDelete] = useState(null);
  // productcomponent usestate
  const [editingproductcomponents, setEditingproductcomponents] = useState(null);
  const [productCategory, setproductCategory] = useState('');
  const [productCode, setproductCode] = useState("");
  const [productName, setproductName] = useState("");
  const [salesVolumesPerPack, setsalesVolumesPerPack] = useState("");
  const [packSize, setpackSize] = useState("")
  const [salesVolumes, setsalesVolumes] = useState("");
  const [size, setsize] = useState("");
  const [productComponentCategory, setproductComponentCategory] = useState('');
  const [subCategory, setsubCategory] = useState('');
  const [itemCode, setitemCode] = useState("");
  const [usage, setusage] = useState("");
  const [UOM, setUOM] = useState("");
  const [substrateType, setsubstrateType] = useState("");
  const [standardisedSubstrateType, setstandardisedSubstrateType] = useState('');
  const [PRO, setPRO] = useState("");
  const [weight, setweight] = useState("");
  const [recycledContent, setrecycledContent] = useState("");
  const [recyclable, setrecyclable] = useState("");
  const [salesWeight, setsalesWeight] = useState("");
  const [substrateweight, setsubstrateweight] = useState('')
  //validation 
  const [productCategoryError, setproductCategoryError] = useState("");
  const [productCodeError, setproductCodeError] = useState("");
  const [salesVolumesPerPackError, setsalesVolumesPerPackError] = useState("");
  const [packSizeError, setpackSizeError] = useState("");
  const [salesVolumesError, setsalesVolumesError] = useState("");
  const [sizeError, setsizeError] = useState("");
  const [productComponentCategoryError, setproductComponentCategoryError] = useState("");
  const [subCategoryError, setsubCategoryError] = useState("");
  const [itemCodeError, setitemCodeError] = useState("");
  const [usageError, setusageError] = useState("");
  const [UOMError, setUOMError] = useState("");
  const [substrateTypeError, setsubstrateTypeError] = useState("");
  const [standardisedSubstrateTypeError, setstandardisedSubstrateTypeError] = useState("");
  const [weightError, setweightError] = useState("");
  const [recycledContentError, setrecycledContentError] = useState("");
  const [PROError, setPROError] = useState("");
  const [recyclableError, setrecyclableError] = useState("");
  const [salesWeightError, setsalesWeightError] = useState("");
  const [productNameError, setproductNameError] = useState("");


  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    // Clear form fields

  };
  const handleClose = () => {
    setOpen(false);
    clearFormFields()
    //it defines edit values will be null
    setEditingproductcomponents(null);
    clearFormvalidations()
  };

  //clear input fileds
  const clearFormFields = () => {
    setproductCategory("");
    setproductCode("");
    setsalesVolumesPerPack("");
    setproductComponentCategory("");
    setpackSize("");
    setsalesVolumes("")
    setsize("");
    setitemCode("");
    setusage("");
    setUOM("");
    setweight("");
    setrecycledContent("");
    setsubstrateType("");
    setPRO("");
    setstandardisedSubstrateType("");
    setrecyclable("");
    setsalesWeight("");
    setproductName("");
    setsubCategory("");
    setsubstrateweight('')
  }

  //clear validation
  const clearFormvalidations = () => {
    setproductCategoryError("");
    setproductCodeError("");
    setproductComponentCategoryError("")
    setsalesVolumesPerPackError("");
    setproductCategoryError("");
    setpackSizeError("");
    setsalesVolumesError("")
    setsizeError("");
    setitemCodeError("");
    setusageError("");
    setUOMError("");
    setweightError("");
    setrecyclableError("");
    setsubstrateTypeError("");
    setPROError("");
    setstandardisedSubstrateTypeError("");
    setrecycledContentError("");
    setsalesWeightError("");
    setsubCategoryError("");
    setproductNameError("")
  }

  //delete api call
  const handleClickOpen1 = (productComponent) => {
    setSelectedProductComponent(productComponent);
    setOpen1(true);
    console.log(productComponent)
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleDeleteClick = (componentId) => {
    setcomponentIdToDelete(componentId);
    setOpenDialog(true);
  };


  const handleCloseDialog = () => {
    setOpenDialog(false);
    setcomponentIdToDelete(null);
  };

  useEffect(() => {
    getPermissions();
  }, [])


  async function getPermissions() {
    try {
      const response = await instance.get(`users/permissions/${id}`);
      if (response.data.status === 'success') {
        const perm = response.data.data.find(item => item.itemName === "ProductComponent")
        // console.log(perm)
        setPermissions({ read: perm.read, write: perm.write })
      }
    } catch (err) {
      console.log(err);
    }
  }


  //getproductcomponents
  useEffect(() => {
    getproductcomponents()
  }, [])

  const getproductcomponents = async () => {
    try {
      const response = await instance.get("productComponents");
      setproductcomponents(response.data.productComponents)
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching productcomponents:', error.message);
    }
  }
  //get products
  const [products, setproducts] = useState([]);

  useEffect(() => {
    if (productCategory) {
      getProducts(productCategory);
    } else {
      setproducts([]); // Clear products if no category is selected
    }
  }, [productCategory]);


  const getProducts = async (categoryId) => {
    try {
      const response = await instance.get(`products/category/${categoryId}`);
      setproducts(response.data.products);
    } catch (error) {
      console.error('Error fetching products:', error.message);
    }
  };



  useEffect(() => {
    getProductCategories();
  }, []);

  const getProductCategories = async () => {
    try {
      const response = await instance.get('productCategories');
      setProductCategories(response.data.productCategories);
    } catch (error) {
      console.error('Error fetching product categories:', error.message);
    }
  };

  //get substrate data
  const [substrate, setsubstrate] = useState([]);

  useEffect(() => {
    getsubstrate()
  }, []);

  const getsubstrate = async () => {
    try {
      const response = await instance.get("substrates");
      setsubstrate(response.data.substrates);
      console.log(response.data.substrates);
    }
    catch (error) {
      console.error('Error fetching substrates:', error.message);
    }
  }


  //validation function
  const validateForm = () => {
    let isValid = true;

    // product name validation
    if (!productName) {
      setproductNameError("Please select a product name");
      isValid = false;
    } else {
      setproductNameError("");
    }

    // Validate productCategory
    if (!productCategory) {
      setproductCategoryError("Please select a product category");
      isValid = false;
    } else {
      setproductCategoryError("");
    }
    if (!productComponentCategory) {
      setproductComponentCategoryError("Please select a product component category");
      isValid = false;
    } else {
      setproductComponentCategoryError("");
    }

    // Product code validation
    if (!productCode) {
      setproductCodeError("Please select a productCode");
      isValid = false;
    } else {
      setproductCodeError("");
    }

    // Product salesVolumesPerPackRegex validation
    // const salesVolumesPerPackRegex = /^\d+$/; // Regex to match only numbers
    // if (!salesVolumesPerPack) {
    //   setsalesVolumesPerPackError("Please enter product salesVolumesPerPack");
    //   isValid = false;
    // } else if (!salesVolumesPerPackRegex.test(salesVolumesPerPack.toString().trim())) {
    //   setsalesVolumesPerPackError("Product item must contain only numbers");
    //   isValid = false;
    // } else {
    //   setsalesVolumesPerPackError("");
    // }

    if (!salesVolumesPerPack) {
      setsalesVolumesPerPackError("Please enter product salesVolumesPerPack");
      isValid = false;
    } else {
      setsalesVolumesPerPackError("");
    }

    // Product itemCode validation
    // const itemCodeRegex = /^\d{2}-\d{3}-\d{2}$/; // Regex to match only numbers
    // if (!itemCode) {
    //   setitemCodeError("Please enter itemCode");
    //   isValid = false;
    // } else if (!itemCodeRegex.test(itemCode.toString().trim())) {
    //   setitemCodeError("Product item must contain only numbers");
    //   isValid = false;
    // } else {
    //   setitemCodeError("");
    // }

    // Product code validation
    if (!itemCode) {
      setitemCodeError("Please enter itemCode");
      isValid = false;
    } else {
      setitemCodeError("");
    }

    // Product itemCode validation
    // const packSizeRegex = /^\d+$/; // Regex to match only numbers
    // if (!packSize) {
    //   setpackSizeError("Please enter packSize");
    //   isValid = false;
    // } else if (!packSizeRegex.test(packSize.toString().trim())) {
    //   setpackSizeError("Product item must contain only numbers");
    //   isValid = false;
    // } else {
    //   setpackSizeError("");
    // }
    if (!packSize) {
      setpackSizeError("Please enter packSize");
      isValid = false;
    } else {
      setpackSizeError("");
    }

    // salesVolumes validation
    // const salesVolumesRegex = /^\d+$/; // Regex to match only numbers
    // if (!salesVolumes) {
    //   setsalesVolumesError("Please enter product salesVolumes");
    //   isValid = false;
    // } else if (!salesVolumesRegex.test(salesVolumes.toString().trim())) {
    //   setsalesVolumesError("Product item must contain only numbers");
    //   isValid = false;
    // } else {
    //   setsalesVolumesError("");
    // }

    // salesVolumes validation
    const sizeRegex = /^\d+$/; // Regex to match only numbers
    if (!size) {
      setsizeError("Please enter product size");
      isValid = false;
    } else if (!sizeRegex.test(size.toString().trim())) {
      setsizeError("Product item must contain only numbers");
      isValid = false;
    } else {
      setsizeError("");
    }

    // subCategory validation
    if (!subCategory) {
      setsubCategoryError("Please enter subCategory");
      isValid = false;
    } else {
      setsubCategoryError("");
    }


    //usage validation
    const usageRegex = /^\d+$/; // Regex to match only numbers
    if (!usage) {
      setusageError("Please enter usage");
      isValid = false;
    } else if (!usageRegex.test(usage.toString().trim())) {
      setusageError("Usage must contain only numbers");
      isValid = false;
    } else {
      setusageError("");
    }

    // UOM validation
    if (!UOM) {
      setUOMError("Please enter UOM");
      isValid = false;
    } else {
      setUOMError("");
    }

    //substrateType validation
    if (!substrateType) {
      setsubstrateTypeError("Please enter substrateType");
      isValid = false;
    } else {
      setsubstrateTypeError("");
    }

    //standardisedSubstrateType validation
    if (!standardisedSubstrateType) {
      setstandardisedSubstrateTypeError("Please enter standardisedSubstrateType");
      isValid = false;
    } else {
      setstandardisedSubstrateTypeError("");
    }

    //PRO validation
    if (!PRO) {
      setPROError("Please enter PRO");
      isValid = false;
    } else {
      setPROError("");
    }


    //weight validation
    const weightRegex = /^\d+$/; // Regex to match only numbers
    if (!weight) {
      setweightError("Please enter weight");
      isValid = false;
    } else if (!weightRegex.test(weight.toString().trim())) {
      setweightError("weight must contain only numbers");
      isValid = false;
    } else {
      setweightError("")
    }


    //recycledContent validation
    const recycledContentRegex = /^\d+$/; // Regex to match only numbers
    if (!recycledContent) {
      setrecycledContentError("Please enter recycledContent");
      isValid = false;
    } else if (!recycledContentRegex.test(recycledContent.toString().trim())) {
      setrecycledContentError("recycledContent must contain only numbers");
      isValid = false;
    } else {
      setrecycledContentError("");
    }


    //recyclable validation
    if (!recyclable) {
      setrecyclableError("Please Enter recyclable");
      isValid = false;
    } else {
      setrecyclableError("");
    }

    //salesWeight validation
    const salesWeightRegex = /^\d+$/; // Regex to match only numbers
    if (!salesWeight) {
      setsalesWeightError("Please enter salesWeight");
      isValid = false;
    } else if (!salesWeightRegex.test(salesWeight.toString().trim())) {
      setsalesWeightError("salesWeight must contain only numbers");
      isValid = false;
    } else {
      setsalesWeightError("");
    }

    return isValid;
  };



  //addproductcomponents
  const addproductcomponents = async () => {

    if (!validateForm()) {
      return;
    }
    try {
      const response = await instance.post("productComponents", {
        category: productCategory,
        productCode: products.find(item => item.productName === productName)._id,
        product: products.find(item => item.productName === productName)._id,
        salesVolumesPerPack: products.find(item => item.productName === productName)._id,
        packSize : products.find(item => item.productName === productName)._id,
        salesVolumes,
        size,
        productComponentCategory,
        subCategory: subCategories.find(item => item.name === subCategory)._id,
        itemCode,
        usage,
        UOM,
        substrateType,
        standardisedSubstrateType,
        PRO,
        weight,
        recycledContent,
        recyclable,
        salesWeight,
        substrateweight
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      })
      // Refresh the product components list
      getproductcomponents();
      handleClose();
    } catch (error) {
      console.error('Error adding productcomponents:', error.message);
      toast.error("Error adding productcomponents")
    }
  }

  // API call for updating substrates
  const handleEditClick = (productComponent) => {
    setEditingproductcomponents(productComponent);
    // Pre-fill the input fields with the existing data
    setproductCategory(productComponent?.category?._id || "");
    setproductName(productComponent?.product?.productName || "");
    setproductCode(productComponent?.productCode?.productCode || "");
    setsalesVolumesPerPack(productComponent?.salesVolumesPerPack || "");
    setpackSize(productComponent.packSize || "");
    setsalesVolumes(productComponent.salesVolumes || "")
    setsize(productComponent.size || "");
    setproductComponentCategory(productComponent?.productComponentCategory || "");
    setsubCategory(productComponent?.subCategory?.name || "")
    setitemCode(productComponent.itemCode || "");
    setusage(productComponent.usage || "");
    setUOM(productComponent.UOM || "");
    setweight(productComponent.weight || "");
    setrecycledContent(productComponent.recycledContent || "");
    setsubstrateType(productComponent?.substrateType?._id || "");
    setstandardisedSubstrateType(productComponent.standardisedSubstrateType || "")
    setPRO(productComponent.PRO || "")
    setrecyclable(productComponent?.recyclable === true ? "true" : "false" || "");
    setsalesWeight(productComponent.salesWeight || "");
    setsubstrateweight(productComponent.substrateweight || "")
    //modal open
    setOpen(true);
  };

  //update
  const updateproductcomponents = async () => {
    if (!validateForm()) {

      return;
    }
    try {
      const response = await instance.patch(`productComponents/${editingproductcomponents._id}`,
        {
          category: productCategory,
          productCode: products.find(item => item.productName === productName)._id,
          product: products.find(item => item.productName === productName)._id,
          salesVolumesPerPack: products.find(item => item.productName === productName)._id,
          subCategory: subCategories.find(item => item.name === subCategory)._id,
          productComponentCategory,
          packSize: products.find(item => item.productName === productName)._id,
          salesVolumes,
          size,
          itemCode,
          usage,
          UOM,
          weight,
          recycledContent,
          substrateType,
          PRO,
          standardisedSubstrateType,
          recyclable,
          salesWeight,
          substrateweight
        });
      handleClose();
    } catch (error) {
      console.error("Error updating productcomponents:", error.message);
      // toast.error("Error updating productcomponents")
    }
  }



  const handleConfirmDelete = async () => {
    try {
      await instance.delete(`productComponents/${componentIdToDelete}`);
      // Refresh the substrate list after deletion
      getproductcomponents();
      console.log(`component with ID ${componentIdToDelete} deleted successfully.`);
    } catch (error) {
      console.error(`Error deleting component with ID ${componentIdToDelete}:`, error.message);
      toast.error("Error deleting productcomponents")
    } finally {
      handleCloseDialog();
    }
  };


  //  api call for get userdata
  const [subCategories, setsubCategories] = useState([]);
  //getsubCategories
  useEffect(() => {
    getsubCategories();
  }, []);

  const getsubCategories = async () => {
    try {
      const response = await instance.get(`subCategories`);
      setsubCategories(response.data.subCategories);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    }
  };
  const filter = subCategories.filter(data => data.category === productComponentCategory);



  //export data integration
  const handleDownloadExportData = async () => {
    try {
      const response = await instance.get("productComponents/downloadExcel", {
        responseType: 'blob', // Treat the response as a Blob
      });
      downloadExportData(response.data);
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching export data:", error.message);
    }
  };

  const downloadExportData = (data) => {
    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "productComponents.xlsx"); // Set the filename for the downloaded file
      document.body.appendChild(link);
      link.click();
    }
  };
  console.log(productCategory)

  return (
    <div className="user_bg_form">
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {editingproductcomponents ? " Update Product Component" : " Add Product Component"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">Select Product Category </InputLabel>
                      <Select
                        id="outlined-basic"
                        label="Select Product Category"
                        type="text"
                        value={productCategory || ''}
                        onChange={(e) => {
                          setproductCategory(e.target.value);
                          setproductCategoryError("");
                        }}
                      >
                        {productCategories.map((category) => (
                          <MenuItem key={category._id} value={category._id}>
                            {category.productCategory}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(productCategoryError)}>
                        {productCategoryError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="Product Name">Product code </InputLabel>
                      <Select
                        id="outlined-basic"
                        label="Select Product code"
                        value={productCode || ''}
                        onChange={(e) => {
                          setproductCode(e.target.value);
                          setproductCodeError("")
                          setproductName(products.find(item => item.productCode === e.target.value).productName)
                        }}

                      >
                        {products.map((product) => (
                          <MenuItem key={product._id} value={product.productCode}>
                            {product.productCode}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean({ productCodeError })}>
                        {productCodeError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">productName </InputLabel>
                      <Select
                        id="outlined-basic "
                        label="productName "
                        value={productName || ''}
                        onChange={(e) => { setproductName(e.target.value); setproductNameError('') }}
                      >
                        {products.map((product) => (
                          <MenuItem key={product._id} value={product.productName}>
                            {product.productName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(productNameError)}>
                        {productNameError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="sales VolumesPerPack"
                      variant="outlined"
                      value={salesVolumesPerPack || ''}
                      onChange={(e) => { setsalesVolumesPerPack(e.target.value); setsalesVolumesPerPackError(""); }}
                      required
                    />
                    <FormHelperText error={Boolean(salesVolumesPerPackError)}>
                      {salesVolumesPerPackError}
                    </FormHelperText>
                  </Grid> */}
                  {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter packSize"
                      variant="outlined"
                      value={packSize}
                      onChange={(e) => { setpackSize(e.target.value); setpackSizeError("") }}
                      required
                    />
                    <FormHelperText error={Boolean(packSizeError)}>
                      {packSizeError}
                    </FormHelperText>
                    packSize
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">sales VolumesPerPack</InputLabel>
                      <Select
                        id="outlined-basic "
                        label="sales VolumesPerPack"
                        value={salesVolumesPerPack || ''}
                        onChange={(e) => { setsalesVolumesPerPack(e.target.value); setsalesVolumesPerPackError(""); }}
                      >
                        {products.map((product) => (
                          <MenuItem key={product._id} value={product._id}>
                            {product.salesVolumesPerPack}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(salesVolumesPerPackError)}>
                        {salesVolumesPerPackError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">packSize</InputLabel>
                      <Select
                        id="outlined-basic "
                        label="packSize"
                        value={packSize || ''}
                        onChange={(e) => { setpackSize(e.target.value); setpackSizeError("") }}
                        required
                      >
                        {products.map((product) => (
                          <MenuItem key={product._id} value={product._id}>
                            {product.packSize}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(packSizeError)}>
                        {packSizeError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="sales Volumes"
                      variant="outlined"
                      value={salesVolumes}
                      onChange={(e) => setsalesVolumes(e.target.value)}
                    />
                    {/* /*<FormHelperText error={Boolean(salesVolumesError)}>
                      {salesVolumesError}
                    </FormHelperText> */}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="size"
                      variant="outlined"
                      value={size}
                      onChange={(e) => { setsize(e.target.value); setsizeError("") }}
                      required
                    />
                    <FormHelperText error={Boolean(sizeError)}>
                      {sizeError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">Select productComponentCategory</InputLabel>
                      <Select
                        id="outlined-basic"
                        label="select-productComponentCategory"
                        variant="outlined"
                        value={productComponentCategory}
                        onChange={(e) => { setproductComponentCategory(e.target.value); setproductComponentCategoryError("") }}
                      >
                        <MenuItem value="Primary">Primary</MenuItem>
                        <MenuItem value="Secondary">Secondary</MenuItem>
                        <MenuItem value="Tertiary">Tertiary</MenuItem>
                      </Select>
                      <FormHelperText error={Boolean(productComponentCategoryError)}>
                        {productComponentCategoryError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="Select subCategory">Select subCategory</InputLabel>
                      <Select
                        id="outlined-basic"
                        label="Select subCategory"
                        value={subCategory}
                        onChange={(e) => { setsubCategory(e.target.value); setsubCategoryError("") }}
                      >
                        {filter?.map((subCat) => (
                          <MenuItem key={subCat._id} value={subCat.name}>
                            {subCat.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(subCategoryError)}>
                        {subCategoryError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Item Code"
                      variant="outlined"
                      value={itemCode}
                      onChange={(e) => { setitemCode(e.target.value); setitemCodeError("") }}
                      required
                    />
                    <FormHelperText error={Boolean(itemCodeError)}>
                      {itemCodeError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Usage"
                      variant="outlined"
                      value={usage}
                      onChange={(e) => {
                        setusage(e.target.value);
                        setusageError("")
                      }}
                      required
                    />
                    <FormHelperText error={Boolean(usageError)}>
                      {usageError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter UOM"
                      variant="outlined"
                      value={UOM}
                      onChange={(e) => {
                        setUOM(e.target.value);
                        setUOMError("")
                      }}
                      required
                    />
                    <FormHelperText error={Boolean(UOMError)}>
                      {UOMError}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">Select Substrate Type </InputLabel>
                      <Select
                        id="outlined-basic"
                        label="Select Substrate Type"
                        variant="outlined"
                        value={substrateType || ''}
                        onChange={(e) => {
                          setsubstrateType(e.target.value);
                          setsubstrateTypeError("");
                        }}
                        required
                      >
                        {substrate.map((substrate) => (
                          <MenuItem key={substrate._id} value={substrate._id}>
                            {substrate?.substrateName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(substrateTypeError)}>
                        {substrateTypeError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="standardisedSubstrateType"
                      variant="outlined"
                      value={standardisedSubstrateType}
                      onChange={(e) => { setstandardisedSubstrateType(e.target.value); setstandardisedSubstrateTypeError("") }}
                      required
                    />
                    <FormHelperText error={Boolean(standardisedSubstrateTypeError)}>
                      {standardisedSubstrateTypeError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="PRO"
                      variant="outlined"
                      value={PRO}
                      onChange={(e) => { setPRO(e.target.value); setPROError("") }}
                      required
                    />
                    <FormHelperText error={Boolean(PROError)}>
                      {PROError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Weight"
                      variant="outlined"
                      value={weight}
                      onChange={(e) => { setweight(e.target.value); setweightError("") }}
                      required
                    />
                    <FormHelperText error={Boolean(weightError)}>
                      {weightError}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Recycled Content"
                      variant="outlined"
                      value={recycledContent}
                      onChange={(e) => { setrecycledContent(e.target.value); setrecycledContentError("") }}
                      required
                    />
                    <FormHelperText error={Boolean(recycledContentError)}>
                      {recycledContentError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">recyclable</InputLabel>
                      <Select
                        id="recyclable"
                        label="recyclable"
                        variant="outlined"
                        value={recyclable}
                        onChange={(e) => { setrecyclable(e.target.value); setrecyclableError("") }}
                      >
                        <MenuItem value="true">true</MenuItem>
                        <MenuItem value="false">false</MenuItem>
                      </Select>
                      <FormHelperText error={Boolean(recyclableError)}>
                        {recyclableError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="salesWeight"
                      variant="outlined"
                      value={salesWeight}
                      onChange={(e) => { setsalesWeight(e.target.value); setsalesWeightError("") }}
                      required
                    />
                    <FormHelperText error={Boolean(salesWeightError)}>
                      {salesWeightError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      id="outlined-basic"
                      label="substrateweight"
                      variant="outlined"
                      value={substrateweight}
                      onChange={(e) => setsubstrateweight(e.target.value)}

                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="cancel_save_user">
              <Button disableRipple disableElevation className="cancel" onClick={handleClose}>
                Cancel
              </Button>
              <Button disableRipple disableElevation className='save_user' onClick={editingproductcomponents ? updateproductcomponents : addproductcomponents}>{editingproductcomponents ? "Update product Component" : "Save product Component"}</Button>
            </div>
          </DialogContent>
        </BootstrapDialog>

      </React.Fragment>
      <React.Fragment>
        <BootstrapDialog className="sales_list"
          onClose={handleClose1}
          aria-labelledby="customized-dialog-title"
          open={open1}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {editingproductcomponents ? "Update product Components" : "Save product Components"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose1}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <div className="add_product_name">
                  <div className="add_product_name_content">
                    <div className="add_product_name_list">
                      <div className="product">
                        <h3>{selectedProductComponent?.productName?.productName}</h3>
                        <h4>ID - {selectedProductComponent?._id}</h4>
                        <h5>
                          <span>
                            <img src={category} alt="" />
                          </span>
                          {selectedProductComponent?.productCategory?.productCategory}
                        </h5>
                      </div>
                      {/* <div className="view_edit">
                        <Button
                          disableRipple
                          disableElevation
                          //  className="edit"
                          // disabled={!permissions.write}
                          className={!permissions.write ? "disable_deactive" : "edit"}
                        >
                          <BorderColorRoundedIcon />
                        </Button>
                      </div> */}
                    </div>
                    <div className="product_item_code">
                      <div className="product_item_code_content">
                        <h4>productCode</h4>
                        <h5>{selectedProductComponent?.productCode?.productCode}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>productComponentCategory</h4>
                        <h5>{selectedProductComponent?.productComponentCategory}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>Item Code</h4>
                        <h5>{selectedProductComponent?.itemCode}</h5>
                      </div>

                    </div>
                    <div className="product_item_code">
                      <div className="product_item_code_content">
                        <h4>packSize</h4>
                        <h5>{selectedProductComponent?.packSize}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>salesVolumesPerPack</h4>
                        <h5>{selectedProductComponent?.salesVolumesPerPack}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>subCategory name</h4>
                        <h5>{selectedProductComponent?.subCategory?.name}</h5>
                      </div>

                    </div>
                    <div className="product_item_code">
                      <div className="product_item_code_content">
                        <h4>salesVolumes</h4>
                        <h5>{selectedProductComponent?.salesVolumes}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>size</h4>
                        <h5>{selectedProductComponent?.size}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>Component Weight</h4>
                        <h5>{selectedProductComponent?.weight}</h5>
                      </div>
                    </div>

                    <div className="product_item_code">
                      <div className="product_item_code_content">
                        <h4>Usage</h4>
                        <h5>{selectedProductComponent?.usage}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>Recycled Content</h4>
                        <h5>{selectedProductComponent?.recycledContent}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>Substrate Type</h4>
                        <h5>{selectedProductComponent?.substrateType?.substrateName}</h5>
                      </div>
                    </div>

                    <div className="product_item_code">
                      <div className="product_item_code_content">
                        <h4>UOM</h4>
                        <h5>{selectedProductComponent?.UOM}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>salesWeight </h4>
                        <h5>{selectedProductComponent?.salesWeight}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>usage </h4>
                        <h5>{selectedProductComponent?.usage}</h5>
                      </div>
                    </div>

                    <div className="product_item_code">
                      <div className="product_item_code_content">
                        <h4>standardisedSubstrateType</h4>
                        <h5>{selectedProductComponent?.standardisedSubstrateType}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>PRO</h4>
                        <h5>{selectedProductComponent?.PRO}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>recyclable </h4>
                        <h5>{selectedProductComponent?.recyclable === true ? "true" : "false"}</h5>
                      </div>
                    </div>
                    <div className="product_item_code">
                      <div className="product_item_code_content">
                        <h4>productCategory </h4>
                        <h5>{selectedProductComponent?.category?.productCategory}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>productName</h4>
                        <h5>{selectedProductComponent?.product?.productName}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>usage </h4>
                        <h5>{selectedProductComponent?.usage}</h5>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="cancel_save_user">
              <Button disableRipple disableElevation className="cancel" onClick={handleClose1}>
                Cancel
              </Button>
              {/* <Button disableRipple disableElevation className="save_user">
                Save
              </Button> */}
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <React.Fragment>
        <BootstrapDialog
          className="sales_delete"
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
        >
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <div className="delete_sales_list">
                  <h3>Delete product components List</h3>
                  <p>
                    Are you sure you want to delete the productcomponents record from productcomponents
                    list?
                  </p>
                  <div className="cancel_save_user">
                    <Button
                      disableRipple
                      disableElevation
                      className="cancel"
                      onClick={handleCloseDialog}
                    >
                      Cancel
                    </Button>
                    <Button
                      disableRipple
                      disableElevation
                      className="save_user"
                      onClick={handleConfirmDelete}
                    >
                      Yes, Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <Box>
        <Box>
          <Topbar />
          <section className="main_box">
            <div className="table_box">
              <TableContainer component={Paper}>
                <div className="table_inbox">
                  <div className="add_user_list">
                    <div className="add_user_content">
                      <h3>Product Component</h3>
                    </div>
                    <div className="add_user add_user_list_1">
                      <div className={!permissions.write ? "disable_deactive" : "import_menu"}>
                        <IconButton
                          id="fade-button"
                          aria-controls={open2 ? 'fade-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open2 ? 'true' : undefined}
                          onClick={handleClick2}
                        >
                          {/* <ExcelIcon /> */}
                          <img src={microsoftexcel} alt="microsoft-excel" />
                        </IconButton>
                        <Menu
                          id="fade-menu"
                          MenuListProps={{
                            'aria-labelledby': 'fade-button',
                          }}
                          anchorEl={anchorE2}
                          open={open2}
                          onClose={handleClose2}
                          TransitionComponent={Fade}
                        >
                          <MenuItem onClick={handleDownloadExportData}>Export</MenuItem>
                        </Menu>
                      </div>
                      <Button
                        variant="outlined"
                        disableRipple
                        disableElevation
                        onClick={handleClickOpen}
                        className={!permissions.write ? "disable_deactive" : "add_user button"}
                      >
                        <span className="icon">
                          <img className="add_user_image" src={add_sales_image} />
                        </span>
                        <span className="content">Add Product Component</span>
                      </Button>
                    </div>
                  </div>
                  <div className="product_components">
                    <Grid container spacing={2}>
                      {currentproductcomponents.map((productComponent, index) => (
                        <Grid item lg={4} md={6} sm={6} key={productComponent._id} >
                          <div className="product_components_box" >
                            <div className="bg">
                              <img src={product_bg} alt="" />
                            </div>
                            <div className="product_components_box_main">
                              <div className="product_components_box_item">
                                <h4>Product code : {productComponent.productCode?.productCode}</h4>
                                <h5>
                                  <span>
                                    <img src={category} alt="" />
                                  </span>
                                  {productComponent.productComponentCategory}
                                </h5>
                              </div>
                              <div className="view_edit">
                                <Button
                                  disableRipple
                                  disableElevation
                                  className="view"
                                  onClick={() => handleClickOpen1(productComponent)}
                                >
                                  View
                                </Button>
                                <Button
                                  disableRipple
                                  disableElevation
                                  onClick={() => handleEditClick(productComponent)}
                                  className={!permissions.write ? "disable_deactive" : "edit"}
                                >
                                  <img className="add_user_image" src={edit_btn} />
                                </Button>
                                <Button
                                  disableRipple
                                  disableElevation
                                  onClick={() => handleDeleteClick(productComponent._id)}
                                  className={!permissions.write ? "disable_deactive" : "edit"}
                                >
                                  <img className="add_user_image" src={delete_btn} />
                                </Button>
                              </div>
                            </div>
                            <div className="component_weight">
                              <div className="component_weight_item">
                                <h4>Component Weight</h4>
                                <h5>{productComponent.weight}</h5>
                              </div>
                              <div className="component_weight_item component_weight_item2">
                                <h4>Substrate Type</h4>
                                <h5>{productComponent.substrateType?.substrateName}</h5>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                  <div className="pagination_table">
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, page) => paginate(page)} />
                  </div>
                </div>
              </TableContainer>
            </div>
          </section>
        </Box>
      </Box>
    </div>
  );
}

export default Product_components;
