
import React, { createContext, lazy, useEffect, useState } from "react";
import UserManagement from "./Pages/User_management";
import ProductCategory from "./Pages/Product_category";
import Products from "./Pages/Products";
import SubstrateType from "./Pages/Substrate_type";
import ProductComponents from "./Pages/Product_component";
import SalesList from "./Pages/Sales_list";
import MonthlyEprReport from "./Pages/Monthly_epr_report";
import AnnualEprReport from './Pages/Annual_epr_report'
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/Forgot_password";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidenav from "./layouts/Sidenav";
import { jwtDecode as decode } from "jwt-decode";
import Profile from "./components/Profile";
import ChangePassword from "./components/ChangePassword";
import Importproductfile from "./components/Importproductfile";
import Importsalesentryfile from './components/Importsalesentryfile'
import NotFoundTitle from './components/NotFoundTitle';
import SubCategory from "./Pages/SubCategory";
import Importsubstratefile from "./components/Importsubstratefile";

const Spinner = () => {
  return <div className="spinner"></div>;
};

export const AppContext = createContext();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    const checkAuthentication = async () => {
      console.log("Checking authentication...");
      if (token) {
        let decoded;
        try {
          decoded = decode(token);
          const timestamp = Math.floor(Date.now() / 1000);
          setIsAuthenticated(decoded.exp >= timestamp);
        } catch (error) {
          console.error(error);
          localStorage.removeItem("token");
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false); // Set loading state to false after token check
    };

    checkAuthentication();
  }, []);

  const handleLogin = (token) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
    navigate("/");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/login");
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="App">
        <ToastContainer />
        <AppContext.Provider value={{ isAuthenticated, handleLogin, handleLogout }}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/" element={isAuthenticated ? <Sidenav /> : <Navigate to="/login" />}>
              <Route index element={<UserManagement />} />
              <Route path="usermanagement" element={<UserManagement />} />
              <Route path="products" element={<Products />} />
              <Route path="product_category" element={<ProductCategory />} />
              <Route path="substrate_type" element={<SubstrateType />} />
              <Route path="SubCategory" element={<SubCategory />} />
              <Route path="product_components" element={<ProductComponents />} />
              <Route path="sales_list" element={<SalesList />} />
              <Route path="monthly_epr_report" element={<MonthlyEprReport />} />
              <Route path="annual_epr_report" element={<AnnualEprReport />} />
              <Route path="profile" element={<Profile />} />
              <Route path="changepassword" element={<ChangePassword />} />
              <Route path="importproductfile" element={<Importproductfile />} />
              <Route path="importsalesentryfile" element={<Importsalesentryfile />} />
              <Route path="importsubstratetypefile" element={<Importsubstratefile />} />
              <Route path="logout" element={<Navigate to="/login" replace />} />
              <Route path="*" element={<NotFoundTitle />} />
            </Route>
          </Routes>
        </AppContext.Provider>
      </div>
    </>
  );
}

export default App;


