import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/user_management.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import add_sales_image from '../assets/images/icons/add-icons/add.svg'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import Pagination from '@mui/material/Pagination';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import edit_btn from '../assets/images/icons/add-icons/edit.svg'
import delete_btn from '../assets/images/icons/add-icons/delete.svg'
import instance from "../utlis/Api";
import microsoftexcel from '../assets/images/icons/excel.png';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { Link } from "react-router-dom";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Substrate_type() {
  const [open, setOpen] = React.useState(false);
  const id = localStorage.getItem('userId');
  const [substrate, setsubstrate] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10); // Number of users to display per page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentsubstrate = substrate.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Define state variables for permissions
  const [permissions, setPermissions] = useState({ read: false, write: false });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const [editingsubstrate, setEditingsubstrate] = useState(null);
  const [substrateName, setsubstrateName] = useState();
  const [substrateDescription, setsubstrateDescription] = useState();


  const handleClickOpen = () => {
    setOpen(true);
    // Clear form fields
    clearFormFields();
  };

  const handleClose = () => {
    setOpen(false);
    clearFormFields();
    //if defines edit values will be null
    setEditingsubstrate(null);
  };

  const clearFormFields = () => {
    setsubstrateName("");
    setsubstrateDescription("");
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  // Call getPermissionsForItem function for both products and substrates
  useEffect(() => {
    getsubstrate();
    getPermissions();
  }, []);

  const getsubstrate = async () => {
    try {
      const response = await instance.get("substrates");
      setsubstrate(response.data.substrates);
      console.log(response.data.substrates);
    }
    catch (error) {
      console.error('Error fetching substrates:', error.message);
    }
  }

  //getpermissions
  async function getPermissions() {
    try {
      const response = await instance.get(`users/permissions/${id}`);
      if (response.data.status === 'success') {
        const perm = response.data.data.find(item => item.itemName === "Substrate")
        // console.log(perm)
        setPermissions({ read: perm.read, write: perm.write })
      }
    } catch (err) {
      console.log(err);
    }
  }

  //add substrate
  const addsubstrate = async () => {

    try {
      const response = await instance.post("substrates", {
        substrateName,
        substrateDescription
      });
      console.log(response.data.substrate);
      //clear input fileds
      setsubstrateName("");
      setsubstrateDescription("");
      //refresh the added substrates
      getsubstrate();
      handleClose();
    } catch (error) {
      console.error('Error adding substrate:', error.message);
      toast.error("Error adding substrate")
    }
  }


  // API call for updating substrates
  //edit function
  const handleEditClick = (substrates) => {
    setEditingsubstrate(substrates);
    console.log(substrates._id)
    // Pre-fill the input fields with the existing data
    setsubstrateName(substrates.substrateName || "");
    setsubstrateDescription(substrates.substrateDescription || "");
    //modal open
    setOpen(true);
  };


  const updatesubstrate = async () => {
    if (!substrateName || !substrateDescription) {
      toast.error('All fields are required. Please fill in all the fields.');
      return;
    }
    try {
      const response = await instance.patch(`substrates/${editingsubstrate._id}`,
        {
          substrateName,
          substrateDescription
        });
      console.log(response.data);
      // Refresh the substrate list after create
      getsubstrate()
      handleClose();
    } catch (error) {
      console.error("Error updating substrate:", error.message);
      toast.error("Error updating substrate")
    }
  }

  // Call your delete API or function here
  const [openDialog, setOpenDialog] = useState(false);
  const [substrateIdToDelete, setSubstrateIdToDelete] = useState(null);

  const handleDeleteClick = (substrateId) => {
    setSubstrateIdToDelete(substrateId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSubstrateIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await instance.delete(`substrates/${substrateIdToDelete}`);
      // Refresh the substrate list after deletion
      getsubstrate();
      console.log(`Substrate with ID ${substrateIdToDelete} deleted successfully.`);
    } catch (error) {
      console.error(`Error deleting substrate with ID ${substrateIdToDelete}:`, error.message);
      toast.error("Error deleting substrate")
    } finally {
      handleCloseDialog();
    }
  };


  //export data integration
  const handleDownloadExportData = async () => {
    try {
      const response = await instance.get("substrates/export", {
        responseType: 'blob', // Treat the response as a Blob
      });
      downloadExportData(response.data);
    } catch (error) {
      console.error("Error fetching export data:", error.message);
    }
  };

  const downloadExportData = (data) => {
    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "substrates.xlsx"); // Set the filename for the downloaded file
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div>
      <React.Fragment>
        <BootstrapDialog className="sales_entry"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {editingsubstrate ? "Update Substrate" : "Add New Substrate"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Substrate Name"
                      variant="outlined"
                      value={substrateName}
                      onChange={(e) => setsubstrateName(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Substrate Description"
                      variant="outlined"
                      value={substrateDescription}
                      onChange={(e) => setsubstrateDescription(e.target.value)}
                      required
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="cancel_save_user">
              <Button disableRipple disableElevation className="cancel" onClick={handleClose} >Cancel</Button>
              <Button disableRipple disableElevation className='save_user' onClick={editingsubstrate ? updatesubstrate : addsubstrate}>{editingsubstrate ? "Update substrate" : "Save substrate"}</Button>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <React.Fragment>
        <BootstrapDialog
          className="sales_delete"
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
        >
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <div className="delete_sales_list">
                  <h3>Delete Substrates List</h3>
                  <p>
                    Are you sure you want to delete the Substrates record from Substrates
                    list?
                  </p>
                  <div className="cancel_save_user">
                    <Button
                      disableRipple
                      disableElevation
                      className="cancel"
                      onClick={handleCloseDialog}
                    >
                      Cancel
                    </Button>
                    <Button
                      disableRipple
                      disableElevation
                      className="save_user"
                      onClick={handleConfirmDelete}
                    >
                      Yes, Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <Box>
        <Box >
          <Topbar />
          <section className="main_box">
            <div className="table_box">
              <TableContainer component={Paper}>
                <div className="table_inbox">
                  <div className="add_user_list">
                    <div className="add_user_content">
                      <h3>Substrate Type</h3>
                    </div>
                    <div className="add_user_list_1">
                      <div className={!permissions.write ? "disable_deactive" : "import_menu"}>
                        <IconButton
                          id="fade-button"
                          aria-controls={open1 ? 'fade-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open1 ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          <img src={microsoftexcel} alt="microsoft-excel" />
                        </IconButton>
                        <Menu
                          id="fade-menu"
                          MenuListProps={{
                            'aria-labelledby': 'fade-button',
                          }}
                          anchorEl={anchorEl}
                          open={open1}
                          onClose={handleClose1}
                          TransitionComponent={Fade}
                        >
                          <MenuItem onClick={handleDownloadExportData}>Export</MenuItem>
                          <Link to="/importsubstratetypefile"><MenuItem onClick={handleClose1}>Import</MenuItem></Link>
                        </Menu>
                      </div>
                      <div className="add_user">
                      <Button
                        variant="outlined"
                        disableRipple
                        disableElevation
                        onClick={handleClickOpen}
                        className={!permissions.write ? "disable_deactive" : "add_user button"}
                      >
                        <span className="icon">
                          <img className="add_user_image" src={add_sales_image} />
                        </span>
                        <span className="content">Add Substrate</span>
                      </Button>
                      </div>
                    </div>
                  </div>
                  <Table
                    className="adduser_table user_table"
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow className="table_head">
                        <TableCell>Sl.No</TableCell>
                        <TableCell align="left">Substrate ID</TableCell>
                        <TableCell align="left">Substrate Name</TableCell>
                        <TableCell align="left">Substrate Description</TableCell>
                        <TableCell align="left" className={!permissions.write ? "disable_deactive" : ""}>Manage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table_body">
                      {currentsubstrate.map((substrates, index) => (
                        <TableRow key={substrates._id}>
                          <TableCell align="left">{(index + 1) + (currentPage - 1) * usersPerPage}</TableCell>
                          <TableCell align="left">{substrates._id}</TableCell>
                          <TableCell align="left">{substrates.substrateName}</TableCell>
                          <TableCell align="left">{substrates.substrateDescription}</TableCell>
                          <TableCell align="left" className={!permissions.write ? "disable_deactive" : ""}>
                            <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleDeleteClick(substrates._id)}
                              // className="edit_button delete_button"
                              // disabled={!permissions.write}
                              className={!permissions.write ? "disable_deactive" : "edit_button delete_button"}
                            >
                              {/* <DeleteIcon /> */}
                              <img className="edit_btn_tb add_user_image" src={delete_btn} />
                            </Button>
                            <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleEditClick(substrates)}
                              // className="edit_button"
                              // disabled={!permissions.write}
                              className={!permissions.write ? "disable_deactive" : "edit_button"}
                            >
                              {/* <BorderColorRoundedIcon /> */}
                              <img className="edit_btn_tb add_user_image" src={edit_btn} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="pagination_table">
                    <Pagination
                      count={Math.ceil(substrate.length / usersPerPage)}
                      page={currentPage}
                      onChange={(event, page) => paginate(page)} />
                  </div>
                </div>
              </TableContainer>
            </div>
          </section>
        </Box>
      </Box>
    </div>
  );
}

export default Substrate_type;
