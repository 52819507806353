import React, { useState, useContext } from "react";
import "../Styles/pages/login.css";
import { Button, Container } from "@mui/material";
import login_bg from "../assets/images/login/login_bg.svg";
import logo from "../assets/images/logo.svg";
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppContext } from "../App";
import instance, { updateToken } from "../utlis/Api";

function Login() {
  const { handleLogin } = useContext(AppContext); // Access handleLogin from AppContext
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  const loginUser = async () => {
    try {
      if (!email.trim() || !password.trim()) {
        toast.error('Email and password are required');
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        toast.error('Invalid email format');
        return;
      }

      const response = await instance.post("users/login", {
        email,
        password
      });
      if (response.data.status === "success") {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.data.user._id);
        localStorage.setItem('role', response.data.data.user.role);
        updateToken(response.data.token);
        handleLogin(response.data.token); // Call handleLogin from AppContext
        // toast.success('Login successful');
        // navigate('/usermanagement')
      } else {
        toast.error( response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error( error?.response?.data?.message);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      loginUser();
    }
  };
  return (
    <div>
      <section className="login">
        <ToastContainer />
        <div className="login_bg">
          <img src={login_bg} alt="" />
        </div>
        <Container>
          <div className="login_banner">
            <div className="login_banner_wrapper">
              <div className="login_banner_wrapper_content_main">
                <img src={logo} alt="" />
                <div className="login_banner_wrapper_content">
                  <h2>Welcome back! </h2>
                  <p>Login to access all your data</p>
                </div>
                <div className="login_form">
                  <TextField
                    id="outlined-basic"
                    label="Email ID "
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <FormControl sx={{ mt: 3, width: "25ch" }} variant="outlined" required>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={handleKeyDown} // Attach handleKeyDown here
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      required
                    />
                  </FormControl>
                  <div className="forgot_password">
                    <Button onClick={() => { navigate("/forgot_password") }} disableRipple disableElevation>Forgot Password?</Button>
                  </div>
                  <div className="login_button">
                    <Button onClick={loginUser} disableRipple disableElevation>Login</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default Login;
