import React, { useState, useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/user_management.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import add_product_image from '../assets/images/icons/add-icons/add-product.png'
// import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import Pagination from '@mui/material/Pagination';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import axios from "axios";
import FormHelperText from '@mui/material/FormHelperText';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from "@mui/material/FormControl";
// import * as XLSX from 'xlsx';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
// import ExcelIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Link } from "react-router-dom";
import microsoftexcel from '../assets/images/icons/excel.png'
import edit_btn from '../assets/images/icons/add-icons/edit.svg'
import delete_btn from '../assets/images/icons/add-icons/delete.svg'
import instance from "../utlis/Api";




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function SubCategory() {

    //menu_import
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setname("")
        setcategory("")
        setEditingsubCategory(null)

    };



    //get subCategories
    //get the token in localstorage
    const token = localStorage.getItem("token");
    //permission
    const [permissions, setPermissions] = useState({ read: false, write: false });

    //get id in local storage
    const id = localStorage.getItem('userId');
    //getpermissions
    async function getPermissions() {
        try {
            const response = await instance.get(`users/permissions/${id}`);
            if (response.data.status === 'success') {
                const perm = response.data.data.find(item => item.itemName === "SubCategory")
                // console.log(perm)
                setPermissions({ read: perm.read, write: perm.write })
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        getPermissions();
    }, []);

    //  api call for get userdata
    const [subCategories, setsubCategories] = useState([]);
    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10); // Number of users to display per page
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentproducts = subCategories.slice(indexOfFirstUser, indexOfLastUser);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    //getsubCategories
    useEffect(() => {
        getsubCategories();
    }, []);

    const getsubCategories = async () => {
        try {
            const response = await instance.get(`subCategories`);
            setsubCategories(response.data.subCategories);
        } catch (error) {
            console.error('Error fetching users:', error.message);
        }
    };



    //add subCategories
    const [name, setname] = useState("");
    const [category, setcategory] = useState("");

    const addsubCategories = async () => {
        //validation
        if (!name || !category) {
            toast.error('Please enter subCategories.');
            return;
        }
        try {
            const response = await instance.post("subCategories", {
                name,
                category,
            });
            console.log(response.data)
            setname("");
            setcategory("");
            getsubCategories();
            handleClose();
        } catch (error) {
            console.error('Error fetching products:', error.message);
            toast.error("Error adding category")
        }
    }


    //edit product category
    const [editingsubCategory, setEditingsubCategory] = useState(null);

    const handleEditClick = (subCategory) => {
        setEditingsubCategory(subCategory);
        // Pre-fill the input fields with the existing data
        setname(subCategory?.name || "");
        setcategory(subCategory?.category)
        //modal open
        setOpen(true);
    };

    const updatsubCategories = async () => {
        try {
            const response = await instance.patch(`subCategories/${editingsubCategory._id}`, {
                name,
                category,
            });
            console.log(response.data);
            //get the products
            setname("");
            setcategory("");
            getsubCategories();
            handleClose();
        } catch (error) {
            console.error('Error fetching users:', error.message);
            toast.error("Error updating category")
        }
    }


    //delete poup
    const [openDialog, setOpenDialog] = useState(false);
    const [subcategoryIdToDelete, setsubcategoryIdToDelete] = useState(null);

    // Call your delete API or function here
    const handleDeleteClick = (subcategoryId) => {
        setsubcategoryIdToDelete(subcategoryId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setsubcategoryIdToDelete(null);
    };

    const handleConfirmDelete = async () => {
        try {
            await instance.delete(`subCategories/${subcategoryIdToDelete}`);
            // Refresh the substrate list after deletion
            getsubCategories();
            console.log(`subCategory with ID ${subcategoryIdToDelete} deleted successfully.`);
        } catch (error) {
            console.error(`Error deleting subCategory with ID ${subcategoryIdToDelete}:`, error.message);
            toast.error("Error deleting category")
        } finally {
            handleCloseDialog();
        }
    };


    return (
        <div>
            <React.Fragment>
                <BootstrapDialog
                    className="sales_entry"
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        {editingsubCategory ? "Update subcategory" : "Add subcategory"}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <div className="adduser_bg">
                            <div className="user_form">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Enter Name"
                                            variant="outlined"
                                            value={name}
                                            onChange={(e) => setname(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormControl fullWidth required>
                                            <InputLabel id="demo-simple-select-label">Select category</InputLabel>
                                            <Select
                                                id="outlined-basic"
                                                label="Enter category"
                                                variant="outlined"
                                                value={category}
                                                onChange={(e) => {
                                                    setcategory(e.target.value);
                                                }}
                                            >
                                                <MenuItem value="Primary">Primary</MenuItem>
                                                <MenuItem value="Secondary">Secondary</MenuItem>
                                                <MenuItem value="Tertiary">Tertiary</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className="cancel_save_user">
                            <Button disableRipple disableElevation className="cancel" onClick={handleClose}>Cancel</Button>
                            <Button disableRipple disableElevation className='save_user' onClick={editingsubCategory ? updatsubCategories : addsubCategories}>{editingsubCategory ? "Update subcategory" : "Save subcategory"}</Button>
                        </div>
                    </DialogContent>
                </BootstrapDialog>
            </React.Fragment>
            <React.Fragment>
                <BootstrapDialog
                    className="sales_delete"
                    onClose={handleCloseDialog}
                    aria-labelledby="customized-dialog-title"
                    open={openDialog}
                >
                    <DialogContent>
                        <div className="adduser_bg">
                            <div className="user_form">
                                <div className="delete_sales_list">
                                    <h3>Delete subcategoryt list</h3>
                                    <p>
                                        Are you sure you want to delete the subcategoryt  record from subcategory
                                        list?
                                    </p>
                                    <div className="cancel_save_user">
                                        <Button
                                            disableRipple
                                            disableElevation
                                            className="cancel"
                                            onClick={handleCloseDialog}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            disableRipple
                                            disableElevation
                                            className="save_user"
                                            onClick={handleConfirmDelete}
                                        >
                                            Yes, Delete
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </BootstrapDialog>
            </React.Fragment>
            <Box>
                <Box>
                    <Topbar />
                    <section className="main_box">
                        <div className="table_box">
                            <TableContainer component={Paper}>
                                <div className="table_inbox">
                                    <div className="add_user_list">
                                        <div className="add_user_content">
                                            <h3>subCategory</h3>
                                        </div>
                                        <div className="add_user">
                                                <Button
                                                    variant="outlined"
                                                    disableRipple
                                                    disableElevation
                                                    onClick={handleClickOpen}
                                                    className={!permissions.write ? "disable_deactive" : "add_user button"}
                                                >
                                                    <span className="icon">
                                                        <img className="add_user_image" src={add_product_image} />
                                                    </span>
                                                    <span className="content">Add subCategory</span>
                                                </Button>
                                        </div>
                                    </div>
                                    <Table
                                        className="adduser_table"
                                        sx={{ minWidth: 650 }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow className="table_head">
                                                <TableCell  align="left">Sl.No</TableCell>
                                                <TableCell  align="left">id</TableCell>
                                                <TableCell  align="left">Name</TableCell>
                                                <TableCell  align="left">category</TableCell>
                                                <TableCell align="left" className={!permissions.write ? "disable_deactive" : ""}>Manage</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="table_body">
                                        {currentproducts.map((subCategory, index) => (
                                            <TableRow key={subCategory.id}>
                                                <TableCell align="left">{index + 1}</TableCell>
                                                <TableCell align="left">{subCategory._id}</TableCell>
                                                <TableCell align="left">{subCategory.name}</TableCell>
                                                <TableCell align="left">{subCategory.category}</TableCell>
                                                <TableCell align="left" className={!permissions.write ? "disable_deactive" : ""}>
                                                    <Button
                                                        disableRipple
                                                        disableElevation
                                                        onClick={() => handleDeleteClick(subCategory._id)}
                                                        className={!permissions.write ? "disable_deactive" : "edit_button  delete_button"}
                                                        >
                                                        <img className="edit_btn_tb add_user_image" src={delete_btn} alt="delete" />
                                                    </Button>
                                                    <Button
                                                        disableRipple
                                                        disableElevation
                                                        onClick={() => handleEditClick(subCategory)}
                                                        className={!permissions.write ? "disable_deactive" : "edit_button"}
                                                        >
                                                        <img className="edit_btn_tb add_user_image" src={edit_btn} alt="edit" />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                           </TableBody>
                                    </Table>
                                    <div className="pagination_table">
                                        <Pagination
                                            count={Math.ceil(subCategories.length / usersPerPage)}
                                            page={currentPage}
                                            onChange={(event, page) => paginate(page)}
                                        />
                                    </div>
                                </div>
                            </TableContainer>
                        </div>
                    </section>
                </Box>
            </Box>
        </div >
    );
}

export default SubCategory;
