import PageNotFound from "../assets/images/PageNotFound.avif"
import { Link } from "react-router-dom"

export default function NotFoundTitle() {

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px", backgroundColor: "white" }}>
        <img src={PageNotFound} alt="" />
        <div>
            <h1 style={{fontSize:'xxx-large'}}>404 - Page Not Found</h1>
        </div>
        <Link to="/login">Go to home</Link>
    </div>
  );
}