import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import "../Styles/pages/profile.css";
import axios from "axios";
import Topbar from "../layouts/Topbar";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import instance from "../utlis/Api";
// import profile from '../assets/images/Profile.png'
const cl = "app-page-profile";



export const Profile = () => {
  const [profileData, setprofileData] = useState("");
  // const [imageurl, setImageurl] = useState("https://backendepr.vibhohcm.com/public/img/users");
  //get the token in local storage
  const token = localStorage.getItem("token")

  useEffect(() => {
    fetchProfileData();
  }, []);

  async function fetchProfileData() {
    try {
      const response = await instance.get(`users/me`);
      setprofileData(response.data.user);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }

  const handleProfilePictureChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      uploadProfilePicture(selectedFile);
    }
  };

  const uploadProfilePicture = async (file) => {
    const formData = new FormData();
    formData.append("photo", file);
    try {
      const response = await instance.patch(`users/updateMe`, formData);
      // Handle successful update response
      toast.success("Profile picture uploaded successfully", response.data);
      // Refresh profile data after update
      fetchProfileData();
    } catch (error) {
      // Handle upload error
      console.error("Error uploading profile picture:", error);
      toast.error("Profile picture uploaded unsuccessfully:", error);
    }
  };


  return (
    <>
      <Box>
        <Topbar profileData={profileData} />
        <div className={`${cl}`}>
          <div className={`${cl}__wrapper`}>
            <div className={`${cl}__card`}>
              {/* <div className={`${cl}__image`}>
             <img crossorigin="anonymous"src={`${imageurl}/${profileData?.photo}`} alt="" />
            </div> */}
              <div className={`${cl}__image`}>
                <label htmlFor="profilePictureInput">
                  {/* <img crossorigin="anonymous" src={`${imageurl}/${profileData?.photo}`} alt="" /> */}
                  <img crossorigin="anonymous" src={`${"https://backendepr.vibhohcm.com/public/img/users"}/${profileData?.photo}`} alt="" />
                  <input
                    type="file"
                    id="profilePictureInput"
                    style={{ display: "none" }}
                    onChange={handleProfilePictureChange} />
                </label>
              </div>
              <div className={`${cl}__info`}>
                <ul className={`${cl}__infolist`}>
                  <li className={`${cl}__infolistitem`}>
                    <h6>Name</h6>
                    <p>{profileData?.name}</p>
                  </li>
                  <li className={`${cl}__infolistitem`}>
                    <h6>Email</h6>
                    <p>{profileData?.email}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}

export default Profile;
