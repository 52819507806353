import React, { useContext, useState, useEffect } from "react";
import "../Styles/layouts/topnav.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import menususer from '../assets/images/icons/menu_user.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../App';
import instance from "../utlis/Api";

function Topbar({ profileData }) {
  //get the token in local storage
  const token = localStorage.getItem("token")

  const { handleLogout } = useContext(AppContext); // Access handleLogout from AppContext
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [profile, setprofile] = useState("");
  // const [imageurl, setImageurl] = useState("https://backendepr.vibhohcm.com/public/img/users");

  useEffect(() => {
    fetchProfileData();
  }, [profileData]);


  async function fetchProfileData() {
    try {
      const response = await instance.get(`users/me`);
      setprofile(response.data.user);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }

  return (
    <div>
      <section className="right_bar">
        <div className="topnav_bar">
          <ul>
            <li className="topnav_bar_list">
              {/* <h3>User Management</h3> */}
            </li>
            <li className="topnav_bar_list">
              <div className="menu_item">
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  disableRipple disableElevation
                >
                  <div className="menu_user">
                    {/* <img src={menususer} alt=""/> */}
                    <img crossorigin="anonymous" src={`${"https://backendepr.vibhohcm.com/public/img/users"}/${profile?.photo}`} alt="" />
                    <ExpandMoreIcon />
                  </div>
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem onClick={() => { navigate("/profile") }}>Profile</MenuItem>
                  <MenuItem onClick={() => { navigate("/changepassword") }}>Change Password</MenuItem>
                  <MenuItem onClick={() => { handleLogout() }}>Logout</MenuItem>
                </Menu>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Topbar;
