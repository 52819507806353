import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Box, IconButton, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment } from "@mui/material";
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Topbar from "../layouts/Topbar";
import "../Styles/pages/profile.css";
import instance from "../utlis/Api";

const cl = "app-page-profile";
function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  //get the token in local storage
  const token = localStorage.getItem("token")

  //handle show password
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showPassword1, setShowPassword1] = useState(false);

  const handleTogglePasswordVisibility1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const [showPassword2, setShowPassword2] = useState(false);

  const handleTogglePasswordVisibility2 = () => {
    setShowPassword2((prevShowPassword) => !prevShowPassword);
  };

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords don't match");
      return;
    }
    try {
      const response = await instance.patch('users/updateMyPassword', {
        currentPassword,
        password,
        confirmPassword,
      });

      if (response.data && response.data.status === 'success') {
        toast.success('Password changed successfully');

        // Clear input fields after a short delay
        setTimeout(() => {
          setCurrentPassword('');
          setPassword('');
          setConfirmPassword('');
        }, 1000); // Adjust the delay as needed
      } else {
        // Handle the case when the response indicates an error
        toast.error('Failed to change password');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      toast.error(error.response.data.error);
    }
  };

  return (
    <>
      <Box>
        <Box >
          <Topbar />
          <div className={`${cl}`}>
            <div className={`${cl}__wrapper`}>
              <div className={`${cl}__card`}>
                <h2>Change Password</h2>
                <div className={`${cl}__info`}>
                  <ul className={`${cl}__infolist`}>
                    <li className={`${cl}__infolistitem menu_item`}>
                      {/* <TextField
                                        type="password"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        id="standard-basic"
                                        label="Old Password"
                                        variant="outlined"
                                    /> */}
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Old Password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Old Password"
                        />
                      </FormControl>
                    </li>
                    <li className={`${cl}__infolistitem menu_item`}>
                      {/* <TextField
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        id="standard-basic"
                                        label="New Password"
                                        variant="outlined"
                                    /> */}
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword1 ? 'text' : 'password'}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTogglePasswordVisibility1}
                                edge="end"
                              >
                                {showPassword1 ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="New Password"
                        />
                      </FormControl>
                    </li>
                    <li className={`${cl}__infolistitem menu_item`}>
                          {/* <TextField
                                  type="password"
                                  value={confirmPassword}
                                  onChange={(e) => setConfirmPassword(e.target.value)}
                                  id="standard-basic"
                                  label="Verify Password"
                                  variant="outlined"
                              /> */}
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Verify Password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword2 ? 'text' : 'password'}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTogglePasswordVisibility2}
                                edge="end"
                              >
                                {showPassword2 ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Verify Password"
                        />
                      </FormControl>
                    </li>
                    <li className={`${cl}__infolistitem --button menu_item`}>
                      <Grid sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                        <Button onClick={handleChangePassword} >
                          Update Password
                        </Button>
                      </Grid>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
      <ToastContainer position="top-right" />
    </>
  )
}
export default ChangePassword;
