import axios from 'axios';

// Get the token from localStorage
const token = localStorage.getItem('token');

// Set the base URL for the API
const api = 'https://backendepr.vibhohcm.com/api/v1/';

const instance = axios.create({
    baseURL: `${api}`,
    headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`, // Use the token for Authorization
    }
});

export const updateToken = (newToken) => {
    instance.defaults.headers['Authorization'] = `Bearer ${newToken.trim()}`;
    instance.defaults.headers['x-token'] = newToken    
};

export default instance;
