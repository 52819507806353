import { Box, Button, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import React, { useState, useEffect } from "react";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/user_management.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import add_user_image from '../assets/images/icons/add-icons/add-user.png'
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import active from "../assets/images/icons/active.svg";
import inactive from "../assets/images/icons/inactive.svg";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import edit_btn from '../assets/images/icons/add-icons/edit.svg'
import instance from "../utlis/Api";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function User_management() {
  const token = localStorage.getItem("token");//get the token in localstorage
  const id = localStorage.getItem('userId');  //get id in local storage
  const [open, setOpen] = React.useState(false);
  const [editinguser, setEditinguser] = useState(null);
  const [users, setUsers] = useState([]); //  api call for get userdata
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10); // Number of users to display per page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [userName, setuserName] = useState();
  const [userEmail, setuserEmail] = useState();
  const [role, setrole] = useState("");
  const [userStatuses, setUserStatuses] = useState({});
  const [selectedUser, setSelectedUser] = useState();
  const [ProductcategoryPermission, setProductcategoryPermission] = useState("");
  const [ProductPermission, setProductPermission] = useState("");
  const [substratetypepermission, setsubstratetypepermission] = useState("");
  const [subcategorypepermission, setsubcategorypepermission] = useState("");
  const [productcomponentpermission, setproductcomponentpermission] = useState("");
  const [salespermission, setsalespermission] = useState("");
  const [Reportspermission, setReportspermission] = useState("")
  const [User, setUser] = useState("");
  const [permissions, setPermissions] = useState({ read: false, write: false });  //permission api call

  const handleClickOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    // Reset the state values for user inputs
    setuserName("");
    setuserEmail("");
    setrole("")
    //permission
    setProductcategoryPermission("")
    setProductPermission("")
    setsubstratetypepermission("")
    setproductcomponentpermission("")
    setsalespermission("")
    setReportspermission("");
    setsubcategorypepermission('')
    setUser("")
    // Close the dialog
    setOpen(false);
    setEditinguser(null)
  };

  useEffect(() => {
    getUsers();
  }, []);


  const getUsers = async () => {
    try {
      const response = await instance.get("users");
      const fetchedUsers = response.data.users;

      // Initialize userStatuses with the statuses of fetched users
      const initialUserStatuses = {};
      fetchedUsers.forEach((user) => {
        initialUserStatuses[user._id] = user.status;
      });

      setUsers(fetchedUsers);
      setUserStatuses(initialUserStatuses); // Set the initial statuses
    } catch (error) {
      console.error("Error fetching users:", error.message);
    }
  };



  //api cal for create user
  const addUser = async () => {
    try {
      if (!userName || !userEmail || !role || !ProductcategoryPermission || !ProductPermission || !substratetypepermission || !productcomponentpermission || !salespermission || !Reportspermission || !User 
        ||!subcategorypepermission
      ) {
        toast.error("All fields are mandatory");
        return;
      }

      // Check if email is valid using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userEmail)) {
        toast.error("Invalid email format");
        return;
      }

      const response = await instance.post("users/signup",
        {
          name: userName,
          email: userEmail,
          role: role,
          permissions: [
            { itemName: 'Product', read: ProductPermission !== 'none', write: ProductPermission === 'both' },
            { itemName: 'ProductCategory', read: ProductcategoryPermission !== 'none', write: ProductcategoryPermission === 'both' },
            { itemName: 'Reports', read: Reportspermission !== 'none', write: Reportspermission === 'both' },
            { itemName: 'SalesEntry', read: salespermission !== 'none', write: salespermission === 'both' },
            { itemName: 'Substrate', read: substratetypepermission !== 'none', write: substratetypepermission === 'both' },
            { itemName: 'SubCategory', read: subcategorypepermission !== 'none', write: subcategorypepermission === 'both' },
            { itemName: 'ProductComponent', read: productcomponentpermission !== 'none', write: productcomponentpermission === 'both' },
            { itemName: 'User', read: User !== 'none', write: User === 'both' }
          ]
        },
      );
      handleClose();
      // if (response.data.success === true) {
        // toast.success(response.data.message)
        // If the user is successfully added, display a success message
      //   toast.success(response.data.message);
      //   handleClose();
      //   getUsers();
      // } else {
      //   toast.error(response.data.message);
      // }

    } catch (error) {
      console.error("Error adding user:", error.message);
      toast.error("Error adding user",error)
    }
  };

  const handleStatusChange = (event, userId) => {
    const selectedStatus = event.target.value;
    updateUserStatus(userId, selectedStatus);
  };

  const handleEditClick = (user) => {
    setEditinguser(true);
    setSelectedUser(user);
    setuserName(user.name || "");
    setuserEmail(user.email || "");
    setrole(user.role || "")
    getPermessions(user._id);
    setOpen(true);
  };


  const updateUser = async () => {
    try {
      const response = await instance.patch(`users/updatePermissionForItem`,
        [
          { itemName: 'Product', userId: selectedUser._id, read: ProductPermission !== 'none', write: ProductPermission === 'both' },
          { itemName: 'ProductCategory', userId: selectedUser._id, read: ProductcategoryPermission !== 'none', write: ProductcategoryPermission === 'both' },
          { itemName: 'Reports', userId: selectedUser._id, read: Reportspermission !== 'none', write: Reportspermission === 'both' },
          { itemName: 'SalesEntry', userId: selectedUser._id, read: salespermission !== 'none', write: salespermission === 'both' },
          { itemName: 'Substrate', userId: selectedUser._id, read: substratetypepermission !== 'none', write: substratetypepermission === 'both' },
          { itemName: 'SubCategory', userId: selectedUser._id, read: subcategorypepermission !== 'none', write: subcategorypepermission === 'both' },
          { itemName: 'ProductComponent', userId: selectedUser._id, read: productcomponentpermission !== 'none', write: productcomponentpermission === 'both' },
          { itemName: 'User', userId: selectedUser._id, read: User !== 'none', write: User === 'both' }
        ],
      );
      setEditinguser(response.data)
      toast.success(response.data.message);
      handleClose();
      getUsers();
    } catch (error) {
      console.error("Error updating user:", error.message);
      toast.error("Error updating user")
    }
  };


  const getPermessions = async (id) => {
    try {
      const response = await instance.get(`users/permissions/${id}`,);
      const permissions = response.data.data;
      setProductPermission(() => {
        const permit = permissions.find(item => item.itemName === "Product");
        if (permit) {
          if (permit.read && permit.write) { return 'both' }
          else if (permit.read && !permit.write) { return 'read' }
        }
        return 'none'
      });
      setProductcategoryPermission(() => {
        const permit = permissions.find(item => item.itemName === "ProductCategory");
        if (permit) {
          if (permit.read && permit.write) { return 'both' }
          else if (permit.read && !permit.write) { return 'read' }
        }
        return 'none'
      });
      setsubstratetypepermission(() => {
        const permit = permissions.find(item => item.itemName === "Substrate");
        if (permit) {
          if (permit.read && permit.write) { return 'both' }
          else if (permit.read && !permit.write) { return 'read' }
        }
        return 'none'
      });
      setsubcategorypepermission(() => {
        const permit = permissions.find(item => item.itemName === "SubCategory");
        if (permit) {
          if (permit.read && permit.write) { return 'both' }
          else if (permit.read && !permit.write) { return 'read' }
        }
        return 'none'
      });
      setproductcomponentpermission(() => {
        const permit = permissions.find(item => item.itemName === "ProductComponent");
        if (permit) {
          if (permit.read && permit.write) { return 'both' }
          else if (permit.read && !permit.write) { return 'read' }
        }
        return 'none'
      });
      setsalespermission(() => {
        const permit = permissions.find(item => item.itemName === "SalesEntry");
        if (permit) {
          if (permit.read && permit.write) { return 'both' }
          else if (permit.read && !permit.write) { return 'read' }
        }
        return 'none'
      });
      setReportspermission(() => {
        const permit = permissions.find(item => item.itemName === "Reports");
        if (permit) {
          if (permit.read && permit.write) { return 'both' }
          else if (permit.read && !permit.write) { return 'read' }
        }
        return 'none'
      });
      setUser(() => {
        const permit = permissions.find(item => item.itemName === "User");
        if (permit) {
          if (permit.read && permit.write) { return 'both' }
          else if (permit.read && !permit.write) { return 'read' }
        }
        return 'none'
      });

    } catch (error) {
      console.error(error.message);
    }
  }

  const updateUserStatus = async (userId, status) => {
    try {
      const response = await instance.patch(`users/${userId}`,
        {
          status: status,
      });
      setUserStatuses(response)
      // Update the status of the user in the local state
      setUserStatuses((prevStatuses) => ({
        ...prevStatuses,
        [userId]: status,
      }));
      getUsers();
    } catch (error) {
      console.error("Error updating user status:", error.message);
      toast.error("Error updating user status")
    }
  };


  //getpermissions
  async function getPermissions() {
    try {
      const response = await instance.get(`users/permissions/${id}`);
      if (response.data.status === 'success') {
        const perm = response.data.data.find(item => item.itemName === "User")
        setPermissions({ read: perm.read, write: perm.write })
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getPermissions();
  }, []);


  return (
    <div className="user_bg_form user_management_main">
      <React.Fragment>
        <BootstrapDialog
          className="sales_entry"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {editinguser ? "Update User" : "Add New User"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <Grid container spacing={2}>
                  {!editinguser && (
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="User Name "
                        variant="outlined"
                        value={userName}
                        onChange={(e) => setuserName(e.target.value)}
                        required
                      />
                    </Grid>)}
                  {!editinguser && (
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Enter Email "
                        variant="outlined"
                        value={userEmail}
                        onChange={(e) => setuserEmail(e.target.value)}
                        required
                      />
                    </Grid>)}
                  {!editinguser && (
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth required>
                          <InputLabel id="demo-simple-select-label">Select role</InputLabel>
                          <Select
                            id="Select role"
                            label='Select role'
                            value={role}
                            onChange={(e) => setrole(e.target.value)}
                          >
                            <MenuItem value="admin">admin</MenuItem>
                            <MenuItem value="user">user</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>)}
                  {/* <Grid item xs={12}>
                    {!editinguser && (
                      <TextField
                        id="outlined-basic"
                        label="Enter Password *"
                        variant="outlined"
                        type="password"
                        value={userPassword}
                        onChange={(e) => setUserPassword(e.target.value)}
                      />
                    )}
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    {!editinguser && (
                      <TextField
                        id="outlined-basic"
                        label="Confirm Password *"
                        variant="outlined"
                        type="password"
                        value={userConfirmPassword}
                        onChange={(e) => setUserConfirmPassword(e.target.value)}
                      />
                    )}
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>Product category Permission</FormLabel>
                      <RadioGroup
                        row
                        value={ProductcategoryPermission}
                        onChange={(e) => setProductcategoryPermission(e.target.value)}
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel value="read" control={<Radio />} label="Read" />
                        <FormControlLabel value="both" control={<Radio />} label="Read and write" />
                        <FormControlLabel value="none" control={<Radio />} label="None" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>Product Permission</FormLabel>
                      <RadioGroup
                        row
                        value={ProductPermission}
                        onChange={(e) => setProductPermission(e.target.value)}
                        name="row-radio-buttons-group">
                        <FormControlLabel value="read" control={<Radio />} label="Read" />
                        <FormControlLabel value="both" control={<Radio />} label="Read and write" />
                        <FormControlLabel value="none" control={<Radio />} label="None" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>substrate type permission</FormLabel>
                      <RadioGroup
                        row
                        value={substratetypepermission}
                        onChange={(e) => setsubstratetypepermission(e.target.value)}
                        name="row-radio-buttons-group" >
                        <FormControlLabel value="read" control={<Radio />} label="Read" />
                        <FormControlLabel value="both" control={<Radio />} label="Read and write" />
                        <FormControlLabel value="none" control={<Radio />} label="None" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>subCategory permission</FormLabel>
                      <RadioGroup
                        row
                        value={subcategorypepermission}
                        onChange={(e) => setsubcategorypepermission(e.target.value)}
                        name="row-radio-buttons-group" >
                        <FormControlLabel value="read" control={<Radio />} label="Read" />
                        <FormControlLabel value="both" control={<Radio />} label="Read and write" />
                        <FormControlLabel value="none" control={<Radio />} label="None" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>product component permission</FormLabel>
                      <RadioGroup
                        row
                        value={productcomponentpermission}
                        onChange={(e) => setproductcomponentpermission(e.target.value)}
                        name="row-radio-buttons-group" >
                        <FormControlLabel value="read" control={<Radio />} label="Read" />
                        <FormControlLabel value="both" control={<Radio />} label="Read and write" />
                        <FormControlLabel value="none" control={<Radio />} label="None" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>sales permission</FormLabel>
                      <RadioGroup
                        row
                        value={salespermission}
                        onChange={(e) => setsalespermission(e.target.value)}
                        name="row-radio-buttons-group" >
                        <FormControlLabel value="read" control={<Radio />} label="Read" />
                        <FormControlLabel value="both" control={<Radio />} label="Read and write" />
                        <FormControlLabel value="none" control={<Radio />} label="None" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>Reports permission</FormLabel>
                      <RadioGroup
                        row
                        value={Reportspermission}
                        onChange={(e) => setReportspermission(e.target.value)}
                        name="row-radio-buttons-group">
                        <FormControlLabel value="read" control={<Radio />} label="Read" />
                        <FormControlLabel value="both" control={<Radio />} label="Read and write" />
                        <FormControlLabel value="none" control={<Radio />} label="None" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>User permission</FormLabel>
                      <RadioGroup
                        row
                        value={User}
                        onChange={(e) => setUser(e.target.value)}
                        name="row-radio-buttons-group">
                        <FormControlLabel value="read" control={<Radio />} label="Read" />
                        <FormControlLabel value="both" control={<Radio />} label="Read and write" />
                        <FormControlLabel value="none" control={<Radio />} label="None" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="cancel_save_user">
              <Button disableRipple disableElevation className="cancel">
                Cancel
              </Button>
              <Button
                disableRipple
                disableElevation
                className="save_user"
                onClick={editinguser ? updateUser : addUser}>
                {editinguser ? "Update User" : "Save user"}{" "}
              </Button>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>

      <Box>
        <Box>
          <Topbar />
          <section className="main_box">
            <div className="table_box">
              <TableContainer component={Paper}>
                <div className="table_inbox">
                  <div className="add_user_list">
                    <div className="add_user_content">
                      <h3>User List</h3>
                    </div>
                    <div className="add_user">
                      <Button
                        variant="outlined"
                        disableRipple
                        disableElevation
                        onClick={handleClickOpen}
                        // disabled={!permissions.write}
                        className={!permissions.write ? "disable_deactive" : "add_user button"}
                      >
                        <span className="icon">
                          {/* <PersonAddAltRoundedIcon /> */}
                          <img className="add_user_image" src={add_user_image} />
                        </span>
                        <span className="content">Add User</span>
                      </Button>
                    </div>
                  </div>
                  <Table
                    className="adduser_table user_table"
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow className="table_head">
                        <TableCell>Sl.No</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">User ID</TableCell>
                        <TableCell align="left">Designation</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left" className={!permissions.write ? "disable_deactive" : ""}>Manage</TableCell>
                        <TableCell align="left" className={!permissions.write ? "disable_deactive" : ""}>Edit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table_body">
                      {currentUsers.map((user, index) => (
                        <TableRow key={user._id}>
                          <TableCell align="left">
                            {index + 1 + (currentPage - 1) * usersPerPage}
                          </TableCell>
                          <TableCell align="left">{user.name}</TableCell>
                          <TableCell align="left">{user.email}</TableCell>
                          <TableCell align="left">{user.role}</TableCell>
                          <TableCell align="left">{user.status}</TableCell>
                          <TableCell align="left" className={!permissions.write ? "disable_deactive" : "active_inactive"}>
                            {/* className="active_inactive" */}
                            <div className="active_inactive_content">
                              <ul>
                                <li>
                                  {user.status === "Active" ? (
                                    <img src={active} alt="Active" />
                                  ) : (
                                    <img src={inactive} alt="Inactive" />
                                  )}
                                </li>
                                <li>
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                      {/* {console.log(user)} */}
                                      <NativeSelect
                                        value={userStatuses[user._id]}
                                        onChange={(event) =>
                                          handleStatusChange(event, user._id)
                                        }
                                        inputProps={{
                                          name: "status",
                                          id: "user-status",
                                        }}
                                      // disabled={!permissions.write}

                                      >
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                      </NativeSelect>
                                    </FormControl>
                                  </Box>
                                </li>
                              </ul>
                            </div>

                          </TableCell>
                          <TableCell align="left" className={!permissions.write ? "disable_deactive" : ""}>
                            <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleEditClick(user)}
                              // className="edit_button"
                              // disabled={!permissions.write}
                              className={!permissions.write ? "disable_deactive" : "edit_button"}
                            >
                              {/* <BorderColorRoundedIcon /> */}
                              <img className="edit_btn_tb add_user_image" src={edit_btn} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="pagination_table">
                    <Pagination
                      count={Math.ceil(users.length / usersPerPage)}
                      page={currentPage}
                      onChange={(event, page) => paginate(page)}
                    />
                  </div>
                </div>
              </TableContainer>
            </div>
          </section>
        </Box>
      </Box>
    </div>
  );
}

export default User_management;
