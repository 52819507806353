import React, { useState, useEffect } from 'react';
import '../Styles/layouts/sidenav.css';
import logo from '../assets/images/logo.svg';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import DescriptionIcon from '@mui/icons-material/Description';
import { useLocation, Outlet, Link, useNavigate } from "react-router-dom";
import { Box, Grid } from '@mui/material';
import axios from 'axios';
import user_management_image from '../assets/images/icons/menu/user.svg';
import user_product_setup_image from '../assets/images/icons/menu/product_setup.png';
import user_sales_list_image from '../assets/images/icons/menu/sales_list.png';
import user_reports_image from '../assets/images/icons/menu/report.png';
import instance from '../utlis/Api';


const Sidenav = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [proRoute, setProRoute] = useState('');
  const navigate = useNavigate()


  //permission
  const [permissions, setPermissions] = useState([]);
  //get the token in localstorage
  const token = localStorage.getItem("token");
  //get id in local storage
  const id = localStorage.getItem('userId');

  //get permissions
  async function getPermissions() {
    try {
      const response = await instance.get(`users/permissions/${id}`);
      if (response.data.status === 'success') {
        const resdata = response.data.data;

        // Check if ProductCategory is available
        const productCategoryOption = resdata.find(obj => obj.read === true && obj.itemName === 'ProductCategory');
        if (productCategoryOption) {
          setProRoute('/product_category');
        } else {
          // Check if Product is available
          const productOption = resdata.find(obj => obj.read === true && obj.itemName === 'Product');
          if (productOption) {
            setProRoute('/products');
          } else {
            // Check if Substrate is available
            const substrateOption = resdata.find(obj => obj.read === true && obj.itemName === 'Substrate');
            if (substrateOption) {
              setProRoute('/substrate_type');
            } else {
              // Check if ProductComponent is available
              const productComponentOption = resdata.find(obj => obj.read === true && obj.itemName === 'ProductComponent');
              if (productComponentOption) {
                setProRoute('/product_components');
              } else {
                // Check if SubCategory is available (newly added)
                const subCategoryOption = resdata.find(obj => obj.read === true && obj.itemName === 'SubCategory');
                if (subCategoryOption) {
                  setProRoute('/SubCategory'); // Define your route for subcategory
                } else {
                  // If none of the options are available, set default route
                  setProRoute('');
                }
              }
            }
          }
        }

        setPermissions(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Check for SubCategory permission
  const hasUserPermission = permissions.find((perm) => perm.itemName === "User" && perm.read && perm.write);
  const hasProductCategoryPermission = permissions.find((perm) => perm.itemName === "ProductCategory" && perm.read && perm.write);
  const hasProductsPermission = permissions.find((perm) => perm.itemName === "Product" && perm.read && perm.write);
  const hasSubstratePermission = permissions.find((perm) => perm.itemName === "Substrate" && perm.read && perm.write);
  const hasSubCategoryPermission = permissions.find((perm) => perm.itemName === 'SubCategory' && perm.read && perm.write);
  const hasProductComponentPermission = permissions.find((perm) => perm.itemName === "ProductComponent" && perm.read && perm.write);
  const hasSalesEntryPermission = permissions.find((perm) => perm.itemName === "SalesEntry" && perm.read && perm.write);
  const hasReportsPermission = permissions.find((perm) => perm.itemName === "Reports" && perm.read && perm.write);


  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} lg={2}>
          {permissions.length > 0 && (
            <div className='sidebar-main'>
              <div className='sidebar-image'>
                <span><Link><img src={logo} alt="" /></Link></span>
              </div>
              <div className='sidebar_menu_list'>
                <div>
                  {hasUserPermission && (
                    <Box className="user_management_accordion" >
                      <ul>
                        <li className={splitLocation[1] === "" ? "active" : ""}><img className='nav_img' src={user_management_image} /><Link to=''>User Management</Link></li>
                      </ul>
                    </Box>
                  )}
                  <Accordion >
                    <Link to={proRoute} className={splitLocation[1] === "product_category" || splitLocation[1] === "products" || splitLocation[1] === "substrate_type" || splitLocation[1] === 'SubCategory' || splitLocation[1] === "product_components" ? 'active' : ''}>
                      <AccordionSummary
                        className={`product_content_sm ${splitLocation[1] === "product_category" || splitLocation[1] === "products" || splitLocation[1] === "substrate_type" || splitLocation[1] === 'SubCategory' || splitLocation[1] === "product_components" ? 'active' : ''}`}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <span><img className='nav_img' src={user_product_setup_image} />Product Setup</span>
                      </AccordionSummary>
                    </Link>
                    <AccordionDetails>
                      <ul>
                        {hasProductCategoryPermission && (<li className={splitLocation[1] === "product_category" ? "active" : ""}><Link to='/product_category'>Product Category</Link></li>)}
                        {hasProductsPermission && (<li className={splitLocation[1] === "products" ? "active" : ""}><Link to='/products'>Products</Link></li>)}
                        {hasSubstratePermission && (<li className={splitLocation[1] === "substrate_type" ? "active" : ""}><Link to='/substrate_type'>Substrate Type</Link></li>)}
                        {hasSubCategoryPermission && (<li className={splitLocation[1] === "SubCategory" ? "active" : ""}><Link to='/SubCategory'>SubCategory</Link></li>)}
                        {hasProductComponentPermission && (<li className={splitLocation[1] === "product_components" ? "active" : ""}><Link to='/product_components'>Product Component</Link></li>)}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  {hasSalesEntryPermission &&
                    (<Box className="user_management_accordion">
                      <ul>
                        <li className={splitLocation[1] === "sales_list" ? "active" : ""}><img className='nav_img' src={user_sales_list_image} /><Link to='/sales_list'>Sales List</Link></li>
                      </ul>
                    </Box>
                    )}
                  {hasReportsPermission &&
                    (<Accordion >
                      <Link to="/monthly_epr_report" className={splitLocation[1] === "monthly_epr_report" || splitLocation[1] === "annual_epr_report" ? 'active' : ''}>
                        <AccordionSummary
                          className={`product_content_sm ${splitLocation[1] === "monthly_epr_report" || splitLocation[1] === "annual_epr_report" ? 'active' : ''}`}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4-content"
                          id="panel4-header">
                          <span ><img className='nav_img' src={user_reports_image} />Reports</span>
                        </AccordionSummary>
                      </Link>
                      <AccordionDetails>
                        <ul>
                          <li className={splitLocation[1] === "monthly_epr_report" ? "active" : ""}><Link to='/monthly_epr_report'>Monthly EPR Report</Link></li>
                          <li className={splitLocation[1] === "annual_epr_report" ? "active" : ""}><Link to='/annual_epr_report'> Annual EPR Report</Link></li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                    )}
                </div>
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={10} lg={10}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  )
}

export default Sidenav;




