import { Modal, Box, Typography, Button } from '@mui/material';
import "../Styles/layouts/topnav.css";
import * as XLSX from 'xlsx';
import React, { useState } from "react";
import ExcelIcon from '@mui/icons-material/InsertDriveFileOutlined';
import error_icon from '../assets/images/close.png';
import success_icon from '../assets/images/check-mark.png';
import instance from '../utlis/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Importsubstratefile = () => {
    //import data 
  const [file, setFile] = useState(null);
  const [importResult, setImportResult] = useState({ success: false, message: '', });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImport = async () => {
    if (!file) {
      console.error('Please select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await instance.post("substrates/import", formData);
      // Optionally, handle success response or perform any necessary actions
      setImportResult({ success: true, message: response.data.message });
      setFile(null);
      // Reset the file input value
      document.getElementById('file-input').value = '';
    } catch (error) {
      toast.error('Error importing data:', error.response.data.message);
      // Optionally, handle error response or display error message to the user
      setImportResult({ success: false, message: error.response.data.message });
    }
  };

  //sample file
  const openSampleFile = () => {
    // Create an empty XLSX file with a Blob
    const columnNames = ['Substrate Name', 'Substrate Description'];
    const allData = [columnNames];
    const worksheet = XLSX.utils.aoa_to_sheet(allData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const content = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
    const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element
    const link = document.createElement('a');
    link.href = url;

    // Set the download attribute to specify the filename
    link.setAttribute('download', 'SubstratesampleFile.xlsx'); // Set the filename to 'sampleFiles.xlsx'

    // Trigger download
    link.click();

    // Clean up
    window.URL.revokeObjectURL(url);
  };

  //modal
  const handleCloseModal = () => {
    setImportResult({ success: false, message: '' });
  };
  return (
    <div className='import_products'>
    <Box>
      <Typography variant="h5"> Import substate type</Typography>
      <Button onClick={openSampleFile}><ExcelIcon />Download sample</Button>
    </Box>
    <div className='import_products_rules'>
      <Typography variant="h5">NOTE: <span>Please fill the excel with the below columns data</span></Typography>
      <ul>
        <li>Substrate Name  and Substrate Description are mandatory fields.</li>
      </ul>
    </div>
    <div className='upload_product'>
      <Button onClick={openSampleFile}><ExcelIcon />Download sample</Button>
      <div className='uploaad_product_file'>
        <input id="file-input" type="file" accept=".xlsx" onChange={handleFileChange} />
        <Button onClick={handleImport}>Upload</Button>
      </div>
    </div>
    <div>
      <Modal
        className='modal_main'
        open={importResult.message !== ''}
        onClose={handleCloseModal}
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'white', boxShadow: 24, p: 4, width: 400 }}>
          {/* <Typography variant="h5" className='errorMessage'>{importResult.success ? 'Success!' : 'Error!'}</Typography> */}
          <Typography variant="h5" className='errorMessage'>{importResult.success ? <img src={success_icon} alt='' /> : <img src={error_icon} alt='' />}</Typography>
          <Typography variant="body1" className='errorMessage_1'>{importResult.message}</Typography>
          <Button sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={handleCloseModal}>Close</Button>
        </Box>
      </Modal>
    </div>

  </div>
  )
}

export default Importsubstratefile